import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import configData from '../config.json'
import Footer from '../components/footer/Footer';
import PageTitle from '../components/pagetitle/PageTitle';
import useAuth from '../hooks/useAuth';
import { CircularProgress, Icon, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import closeFill from "@iconify-icons/eva/close-fill";
import EastIcon from '@mui/icons-material/East';
import { useDispatch } from 'react-redux';

function Login(props) {
    const { login } = useAuth();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
 
    useEffect(() => {
      document.body.style.overflow = 'unset';
  
  },[])
    const onSubmit = async (values) => {
        setError(null);
        setLoading(true)
        const response = await axios
          .post(configData.SERVER_URL + configData.userLogin, {
            email: values.email,
            password: values.password,
          })
          .then((rsp) => {
            if (rsp.data.message === "Too much connection with same IP") {
              enqueueSnackbar(
                 "Too much connection with same IP",
                {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  variant: "error",
                  action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </IconButton>
                  ),
                }
              );
            } else if (rsp.data.message === "User not found") {
              enqueueSnackbar( "User not found", {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "error",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
            } else if (rsp.data.message === "Login Success") {
              login({
                email: values.email,
                password: values.password,
              });
    
              enqueueSnackbar("Login Success", {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "success",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
              setTimeout(() => {
                navigate("/profile");
              }, 2000);
            } else if (rsp.data.message === "Wrong password") {
              enqueueSnackbar("Wrong password", {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "error",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
            } else if (
              rsp.data.message ===
              "Updating new password system, this is your new password"
            ) {
              enqueueSnackbar(
                "Updating new password system, this is your new password: " +
                  rsp.data.password,
                {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  autoHideDuration: 15000,
                  variant: "error",
                  action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </IconButton>
                  ),
                }
              );
            } else {
              enqueueSnackbar(rsp.data.message, {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "info",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
            }
          })
          .catch((err) => {
            if (err && err.response) setError(err.response.data.message);
          });
    
        if (response) {
          alert("Welcome back in. Authenticating...");
        }
        setLoading(false);
      };

      const formik = useFormik({
        initialValues: {
          email: "",
          password: "",
          remember: true,
        },
        validationSchema: yup.object({
          password: yup.string().required(),
        }),
        onSubmit,
      });

      const handlelogin = () => {
        window.open(configData.sso_server+'/sso/login?serviceURL='+configData.consumer+'/')
      }

      const handleRegister = () => {
        window.open(configData.sso_server+'/sso/register?serviceURL='+configData.consumer+'/')
      }
    return (
        <div className='wrapper banner2 s2'>

        <section className="page-title">
                <div className="shapex"></div>
                {/* <div className="shape right"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title__body">
                                <div className="page-title__main">
                                    <h4 className="title">Login</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="container loginPage">
                    <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
                        <div className="col-9" style={{textAlign:'center'}}>
                            <div className="block-text center">
                                {/* <h6 className="sub-heading"><span>Get in Touch!</span></h6> */}
                                {/* <h3 className="heading">Let’s Start Working <br />
                                    Together
                                    </h3> */}
                            </div>
                            {/* <div className="touch__main">

                                 <form onSubmit={formik.handleSubmit} className="form-box">
                                    <div className="row">
                                        <div className="col">
                                            <label >Your email</label>
                                        <input type="email" className="form-control" {...formik.getFieldProps('email')}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label >Your password</label>
                                            <input type="password" className="form-control"  {...formik.getFieldProps('password')}/>
                                        </div>

                                    </div>
                                  
                                    <div className="row mb-0">
                                        <div className="col">
                                            <button disabled={loading} className="action-btn" type="submit"><span style={{width: 130}}>{loading ? <CircularProgress size={15}/> : <>{"Login"}</>}</span></button>
                                            
                                        </div>
                                        <Link to="/register" style={{marginTop: 10}}><h6 style={{ color: "#5C27FE"}}>Click here for register</h6></Link>
                                    </div>
                                </form> 
                              

                            </div> */}
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <h4 style={{marginBottom: 30, paddingTop: 50}}>{"Sign in with ExtraLogin"}</h4>
                              <h6 style={{marginBottom: 30, paddingTop: 50}}>{"You can use your extratribes login/password"}</h6>

                              <button className="action-btn" type="submit" onClick={handlelogin}><span style={{width: 170, display: 'flex', justifyContent: 'center'}}>{loading ? <CircularProgress size={15}/> : <>Continue <EastIcon sx={{ml:1}}/></>}</span></button>

                          
                              <button style={{ marginTop: 40}}className="action-btn" type="submit" onClick={handleRegister}><span style={{width: 330, display: 'flex', justifyContent: 'center', alignItems:'center'}}>{loading ? <CircularProgress size={15}/> : <>Click here to register with ExtraLogin <EastIcon sx={{ml:1}}/></>}</span></button>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
          {/* <Footer /> */}
        </div>
    );
}

export default Login;