import React, { useEffect, useState } from 'react';
import './styles.scss'
import LinkIcon from '@mui/icons-material/Link';
import { useChain, useMoralis } from 'react-moralis';
import configData from '../../config.json';
import axios from "axios";
import { useSnackbar } from "notistack";
import { CircularProgress, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from "react-redux";
import { getMyNumbers } from '../../redux/slices/game';

export default function DepositRekk() {
    const { Moralis, authenticate, isAuthenticated, logout, user, web3, enableWeb3, isWeb3Enabled,account } = useMoralis();
	const [istransfer, setIsTransfer] = useState(false);
	const dispatch = useDispatch();
	const [number, setNumber] = useState();
	const [walletProvider, setWalletProvider] = useState();
  const { switchNetwork, chainId, chain } = useChain();
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uuidDeposit, setuuidDeposit] = useState("");
  const { userXtrb } = useAuth();  

  useEffect(() => {
	
		if (account != null) {
			console.log('web3 install:', Moralis.ensureWeb3IsInstalled());
			console.log('isAuthenticated:', isAuthenticated);
			console.log('isWeb3Enabled:', isWeb3Enabled);
			console.log('userAddress:', account);
			console.log('chainId:', chainId);
			console.log('istransfer:', istransfer);
			console.log('chain:', chain);
			console.log('process.env.REACT_APP_REKK_CHAIN:', process.env.REACT_APP_REKK_CHAIN);
			if (account && chain && chain.networkId == process.env.REACT_APP_REKK_CHAIN) setIsTransfer(true);
		}

		if (localStorage.getItem('walletProvider') != null) {
			let walletprovider = localStorage.getItem('walletProvider');
			setWalletProvider(walletprovider);

			if (account && !isWeb3Enabled) {
     
				enableAll(walletprovider);
			}
		}

	}, [chain]);

  function roundDown(number, decimals) {
    decimals = decimals || 0;
    return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
  const validateNumber = (event) => {
    var temp = 0;
    const value = event.target.value;
    temp = value;
    if (value < 0) {
      temp = value * -1;
    }
    temp = roundDown(temp);
    console.log(temp);
    if (temp < 0) {
      setIsTransfer(false);
    } else {
      setIsTransfer(true);
    }
    setNumber(temp);
  };
  async function enableAll(walletprovider) {
		console.log('walletProvider:', walletprovider);
		if (walletprovider == '') {
			await enableWeb3();
		} else {
			let provider = 'walletconnect';
			await enableWeb3({ provider });
		}
	
	}

	async function moralisTransfer() {
		try {
			
			if (account) {
				var uuidD=""
				setProcessing(true);
				await enableWeb3();
				//to wallet 11
				let transferAmount=Moralis.Units.Token(number, '18')
				var apResponseCode=666
				var apResponse;
				const options = {
					type: 'erc20',
					amount: transferAmount,
					receiver: process.env.REACT_APP_REKK_RECEIVER,
					contractAddress: process.env.REACT_APP_REKK_ADDRESS,
				};
				axios
				.post(configData.SERVER_URL + configData.deposit, {
					uuid: userXtrb.id,
					address: account,
					amount: number,
					isdc: 5483215
				})
				.then((reponse) => {
					if (reponse.data.error === 0) {
						apResponseCode=0
						setuuidDeposit(reponse.data.result)
						 uuidD=reponse.data.result
						// enqueueSnackbar('Transaction in treatment', {
						// 	variant: 'info',
						// 	action: (key) => (
						// 		<MIconButton size="small" onClick={() => closeSnackbar(key)}>
						// 			<Icon icon={closeFill} />
						// 		</MIconButton>
						// 	),
						// });
					} else {
						apResponse=reponse.data
						// enqueueSnackbar('Error happened during deposit', {
						// 	variant: 'error',
						// 	action: (key) => (
						// 		<MIconButton size="small" onClick={() => closeSnackbar(key)}>
						// 			<Icon icon={closeFill} />
						// 		</MIconButton>
						// 	),
						// });
					}
					
					setProcessing(false);
					dispatch(getMyNumbers());
				});
				try{
					let result = await  Moralis.transfer(options);

					if (result!=null && apResponseCode==0){
						 enqueueSnackbar('Transaction Pending', {
						 	variant: 'success',
						 	action: (key) => (
						 		<IconButton size="small" onClick={() => closeSnackbar(key)}>
						 			<Icon icon={closeFill} />
						 		</IconButton>
						 	),
						 });

					}else {
						console.log(apResponse)
						console.log(result)
						 enqueueSnackbar('Error happened during deposit', {
						 	variant: 'error',
						 	action: (key) => (
						 		<IconButton size="small" onClick={() => closeSnackbar(key)}>
						 			<Icon icon={closeFill} />
						 		</IconButton>
						 	),
						 });
					}
						}
						catch (error){
						
								var errorMEssage="Error code "+error.code+"->"+error.message
								enqueueSnackbar(errorMEssage, {
									variant: 'error',
									action: (key) => (
										<IconButton size="small" onClick={() => closeSnackbar(key)}>
											<Icon icon={closeFill} />
										</IconButton>
									),
								});
							if (error.code === 4001) {
								console.log("logerr")
								axios
								.post(configData.SERVER_URL + configData.deposit, {
									uuid: uuidD,
									address: account,
									amount: number,
									isdc: 1243384
								})
							}
						}
	
			}
		} catch (ex) {
			setProcessing(false);
			console.log(ex);
		}
	}

  async function moralisConnect() {
		try {
			const isMetaMaskInstalled = await Moralis.isMetaMaskInstalled();

			if (isMetaMaskInstalled) {
				//await authenticate({ signingMessage: 'Earthium Authentication' });
				await enableWeb3();

				localStorage.setItem('walletProvider', '');
			} else {
				await authenticate({
					provider: 'walletconnect',
					chainId: process.env.REACT_APP_REKK_CHAIN,
					mobileLinks: [
						'metamask',
						// "argent",
						'trust',
					],
					signingMessage: 'Earthium Authentication',
				});
				let provider = 'walletconnect';
				localStorage.setItem('walletProvider', provider);
				await enableWeb3({ provider });
			}
			
			setIsTransfer(true);
		} catch (ex) {
			console.log(ex);
		}
	}

  return (
    <div>
        <h5>DEPOSIT KARE FROM YOUR WALLET</h5>
        <h6 style={{marginBottom:20, marginTop: 10}}>Please note that the transfer can take some times, because of the blockchain</h6>
        
        <input className="stripeInput" onChange={(e)=>setNumber(e.target.value)} placeholder="Amount" style={{width: '100%'}}/>
   
       
        {account ? (
			  <div style={{display: 'flex', marginBottom: 15}}><h6 style={{marginRight:20}}>Connected to {account.slice(0, 10)}.....{account.slice(35, 50)}</h6> <LinkIcon style={{marginTop: -3}}/></div>
						) : (
              <div style={{display: 'flex', marginBottom: 15, cursor:'pointer'}} onClick={moralisConnect}><h6 style={{marginRight:20}}>Not Connected</h6><LinkIcon style={{marginTop: -3}}/></div>
						)}

     
        {account && istransfer && chain.networkId == process.env.REACT_APP_REKK_CHAIN ? (     
             <div className='diposit'>
               <button onClick={moralisTransfer} disabled={processing} className="action-btn"> <span style={{ width: 150,textAlign: 'center'}}>{processing ? <CircularProgress size={15}/> : <>{"Deposit KARE"}</>}</span></button>
           
        </div>
        ) :chain && !chain.networkId !== process.env.REACT_APP_REKK_CHAIN  ?(
     
          <div className='diposit'>
            
        <button className="action-btn" disabled><span>Wrong Network </span></button>
      </div>
     ) : (
     
      <div className='diposit'>
        
    <button className="action-btn" disabled><span>Not Connected </span></button>
  </div>
)}


               
      
    </div>
  )
}
