import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { login, register, logout, validateAccount, resetPwd } from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

useAuth.propTypes = {
	method: PropTypes.oneOf(['jwt', 'firebase']),
};

export default function useAuth(method = 'jwt') {
	// JWT Auth
	const dispatch = useDispatch();
	const { initialized, token, user, role, isLoading, isAuthenticated } = useSelector((state) => state.authJwt);
	// JWT Auth
	if (method === 'jwt') {
		return {
			method: 'jwt',
			user: user,
			token: token,
			initialized: initialized,
			role: role,
			isLoading: isLoading,
			isAuthenticated: isAuthenticated,
			userXtrb: user,
		
			login: ({ email, password }) =>
				dispatch(
					login({
						email: email,
						password: password,
					})
				),

			register: ({ email, affiliation }) =>
				dispatch(
					register({
						email: email,
						affiliation: affiliation,
					})
				),

			validateAccount: ({ uuid, code }) =>
				dispatch(
					validateAccount({
						uuid: uuid,
						code: code,
					})
				),

			logout: () => dispatch(logout()),

			resetPassword: ({ email }) => {
				dispatch(
					resetPwd({
						email: email,
					})
				);
			},

			updateProfile: () => {},
		};
	}
}
