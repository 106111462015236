import React , {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from "swiper/react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import DiamondIcon from '@mui/icons-material/Diamond';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import closeFill from "@iconify-icons/eva/close-fill";
import { EffectCoverflow ,Autoplay , Pagination , Navigation } from "swiper";
import Button from '../button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { drawParticipation, getDrawHistory, getMyTickets, getNextDraws } from '../../redux/slices/lottery';
import axios from 'axios';
import configData from '../../config.json'
import { Box, CircularProgress, Icon, IconButton, Modal, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import Speciality from './Speciality';
import Footer from '../footer/Footer';
import Finished from './Finished';
import Roadmap from './Roadmap';
import useAuth from '../../hooks/useAuth';
import ticket from '../../assets/images/ticket.png'
import ticket2 from '../../assets/images/ticket2.png'
import { useNavigate } from 'react-router-dom';
import {  _deleteSession } from '../../redux/slices/authJwt';

function Main(props) {
    const {data} = props;
    const [loading, setLoading] = useState(false)
    const { user } = useAuth(); 
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { myTickets, drawHistory } = useSelector((state) => state.lottery);
    const [nextDraws, setNextDraws] = useState()
    const [type, setType] = useState()
    const [startAt, setStartAt] = useState()
    const [showEnd, setShowEnd] = useState(false)
    const [endAt, setEndAt] = useState()
    const [priceInfo, setPriceInfo] = useState()
    const [priceInfo2, setPriceInfo2] = useState()
    const [numOfTickets, setNumOfTickets] = useState()
    const [numOfAlreadyTickets, setNumOfAlreadyTickets] = useState()
    const navigate = useNavigate();
    const [num, setNum] = useState()
    const [open, setOpen] = React.useState(false);
    const [clickedTab, setClickedTab] = useState(2)
    const [drawData, setDrawData] = useState()
    const [drawForType2, setDrawForType2] = useState()
    const [drawForType3, setDrawForType3] = useState()
    const [drawForType4, setDrawForType4] = useState()
    const [drawForType5, setDrawForType5] = useState()
    const [drawForType6, setDrawForType6] = useState()
    const [drawForType7, setDrawForType7] = useState()

    const scrollRef = useRef()
    const handleOpen = (e) => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#070A29',
        borderRadius: 5,
        boxShadow: 24,
        p: 2,
      };

      useEffect(() => {
        dispatch(getDrawHistory())
        if(user){
          dispatch(getMyTickets(user.id))
        }  
    },[user])

      useEffect(() =>{    
        document.body.style.overflow = 'unset';      
        const init = async () => {
            try {
                const response = await axios.post(configData.SERVER_URL + configData.nextDraws);   
                    setNextDraws(response.data)
                    setDrawForType2(response.data.result?.find(x => x.type === 2))
                    setDrawForType3(response.data.result?.find(x => x.type === 3))
                    setDrawForType4(response.data.result?.find(x => x.type === 4))
                    setDrawForType5(response.data.result?.find(x => x.type === 5))
                    setDrawForType6(response.data.result?.find(x => x.type === 6))
                    setDrawForType7(response.data.result?.find(x => x.type === 7))

            } catch (error) {
              if(error?.request?.status === 477){
                _deleteSession();
                navigate('/login', { replace: true });
               }
            }
        }
        init()
    },[user ])

    function getNumberofTickets() {
        switch (clickedTab) {
            case 2:
              return setNumOfTickets(myTickets?.Blue)
            case 5:
              return setNumOfTickets(myTickets?.Green) 
            case 1:
              return setNumOfTickets(myTickets?.Grey)
            case 6:
              return setNumOfTickets(myTickets?.Orange)
            case 7:
              return setNumOfTickets(myTickets?.Purple) 
            case 3:
              return setNumOfTickets(myTickets?.Red)
            case 4:
              return setNumOfTickets(myTickets?.Yellow)
            default:
              return null
          }
      }

   const handleDraw = async () => {
    setLoading(true)
    if(num){
        try{
          const response = await axios.post(configData.SERVER_URL + configData.drawParticipation, {
            drawUUID: drawData.uuid,
            numbersOfTicket: num
          });
          try {
            const response = await axios.post(configData.SERVER_URL + configData.nextDraws);   
                setNextDraws(response.data)
                setDrawForType2(response.data.result?.find(x => x.type === 2))
                setDrawForType3(response.data.result?.find(x => x.type === 3))
                setDrawForType4(response.data.result?.find(x => x.type === 4))
                setDrawForType5(response.data.result?.find(x => x.type === 5))
                setDrawForType6(response.data.result?.find(x => x.type === 6))
                setDrawForType7(response.data.result?.find(x => x.type === 7))

        } catch (error) {
           console.log(error)
        }
          dispatch(getMyTickets(user.id))
          if(response && response.data.error === 0){
           enqueueSnackbar( response?.data?.message, {
               anchorOrigin: {
                 vertical: "top",
                 horizontal: "left",
               },
               variant: "success",
               action: (key) => (
                 <IconButton size="small" onClick={() => closeSnackbar(key)}>
                   <Icon icon={closeFill} />
                 </IconButton>
               ),
             });
           
          }if(response.data.error === 1 && response.data.code === 1){
            scrollBottom(scrollRef);
          }
          if(!(response.data.error === 3 && response.data.code === 1)){
            handleClose();
          }
          if (response  && response.data.error !== 0) {
            enqueueSnackbar( response?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              variant: "error",
              action: (key) => (
                <IconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </IconButton>
              ),
            });
          } 
        }catch (err) {
          if(err?.request?.status === 477){
            _deleteSession();
            navigate('/login', { replace: true });
           }
        }
        
       }
    
       setLoading(false) 
   }
   const scrollBottom = e => {
    e.current.scrollIntoView({
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date(startAt);
    const difference = currentDate - startDate;
    console.log(difference)
    if(difference > 0){
      setShowEnd(true)
    }else{
      setShowEnd(true)
    }
  },[startAt,endAt])

  const handleTabClick = (e) => {
    setClickedTab(e)
    getNumberofTickets()
}

useEffect(() => {
setDraw()
getNumberofTickets()
},[nextDraws, clickedTab])

useEffect(() => {
  setNumOfTickets(myTickets?.Blue)
},[myTickets])

function setDraw() {
  switch (clickedTab) {
      case 2:
        return setDrawData(drawForType2)
      case 3:
        return setDrawData(drawForType3)
      case 4:
        return setDrawData(drawForType4)
      case 5:
        return setDrawData(drawForType5)
      case 6:
        return setDrawData(drawForType6)
      case 7:
        return setDrawData(drawForType7)
      default:
        return null
    }
}
useEffect(() => {
  setStartAt(drawData?.startAt)
  setEndAt(drawData?.endAt)
  setPriceInfo(drawData?.pricesInfos)
  setPriceInfo2(drawData?.pricesInfos2)
  setNumOfAlreadyTickets(drawData?.ticketAlreadyIn)
},[drawData])

const [days, setDays] = useState(0);
const [hours, setHours] = useState();
const [minutes, setMinutes] = useState();
const [seconds, setSeconds] = useState();

const getTime = () => {
  if(showEnd){
    const time = Date.parse(endAt) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  }else{
    const time = Date.parse(startAt) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  }
};

useEffect(() => {
  if(showEnd){
    const interval = setInterval(() => getTime(endAt), 1000);
    return () => clearInterval(interval);
  }else{
    const interval = setInterval(() => getTime(startAt), 1000);
    return () => clearInterval(interval);
  }
   
}, [startAt,endAt]);
    return (
                <section className="banner s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center">
                                    <h2 className="heading pd">ExtraTribes Lottery</h2>
                                    <div className="faq__mainL flat-tabs">
                                    <Tabs>
                                        <TabList className='menu-tab'>
                                            {drawForType2 ? <button className={clickedTab === 2 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(2)}><DiamondIcon sx={{color: 'blue', fontSize: 35}}/></button>: <button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'blue', fontSize: 35}}/></button>}      
                                            {drawForType5 ? <button className={clickedTab === 5 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(5)}><DiamondIcon sx={{color: 'green', fontSize: 35}}/></button> : <button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'green', fontSize: 35}}/></button>}   
                                            {drawForType6 ? <button className={clickedTab === 6 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(6)}><DiamondIcon sx={{color: 'orange', fontSize: 35}}/></button> : <button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'orange', fontSize: 35}}/></button>}     
                                            {drawForType7 ? <button className={clickedTab === 7 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(7)}><DiamondIcon sx={{color: 'purple', fontSize: 35}}/></button> : <button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'purple', fontSize: 35}}/></button>}  
                                            {drawForType3 ? <button className={clickedTab === 3 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(3)}><DiamondIcon sx={{color: 'red', fontSize: 35}}/></button> : <button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'red', fontSize: 35}}/></button>}     
                                            {drawForType4 ?<button className={clickedTab === 4 ? "lotteryButton lotteryButton_clicked": "lotteryButton"} onClick={() => handleTabClick(4)}><DiamondIcon sx={{color: 'yellow', fontSize: 35}}/></button> :<button className="lotteryButton" disabled style={{opacity: 0.4}}><DiamondIcon sx={{color: 'yellow', fontSize: 35}}/></button>}                   
                                        </TabList>
                                      
                                    </Tabs> 
                                    </div>
                                        {(!showEnd && nextDraws?.result.length !== 0) &&
                                        <div class="draw-time">
                                            <h5 class="subtitle">
                                                Lottery Participation Open In:
                                            </h5>
                                            <div class="draw-counter">
                                                <div data-countdown="2021/12/15"><span>{days}D : </span> <span>{hours}H : </span>  <span>{minutes}M : </span> <span>{seconds}S</span></div>
                                            </div>
                                          </div>}
                                        {(showEnd && nextDraws?.result.length !== 0) &&
                                         <div class="draw-time">
                                         <h5 class="subtitle">
                                             Lottery Participation Close In:
                                         </h5>
                                         <div class="draw-counter">
                                             <div data-countdown="2021/12/15"><span>{days}D : </span> <span>{hours}H : </span>  <span>{minutes}M : </span> <span>{seconds}S</span></div>
                                         </div>
                                       </div>}

                                       { nextDraws?.result.length === 0 &&
                                         <div class="draw-time">
                                          <h5 class="subtitle">
                                            No lottery planned for now
                                          </h5>
                                       </div>}

                                        
                                  {  nextDraws?.result.length !== 0 &&  <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 20}}>
                                      <div class="single-staticstics">
                                          <div class="left">
                                            <div class="icon">
                                              <img src={ticket} alt=""/>
                                            </div>
                                          </div>
                                          <div class="right">
                                            <h4 class="title">Available Tickets</h4>
                                            <div class="count">
                                              <img src={ticket2} alt=""/>
                                              <span>{numOfTickets}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="single-staticstics">
                                            <div class="left">
                                              <div class="icon">
                                                <img src={ticket} alt=""/>
                                              </div>
                                            </div>
                                            <div class="right">
                                              <h4 class="title">Purchased Tickets</h4>
                                              <div class="count">
                                                <img src={ticket2} alt=""/>
                                                <span>{numOfAlreadyTickets}</span>
                                              </div>
                                            </div>
                                          </div>
                                      </div>}
                                      
                                        
                                       { (numOfTickets === 0 && nextDraws?.result.length !== 0 ) && 
                                       <button className="action-btn" onClick={() => scrollBottom(scrollRef)}><span>Switch Ticket</span></button> }
                                      { (numOfTickets !== 0 && nextDraws?.result.length !== 0) && <button className="action-btn" onClick={handleOpen}><span>Participate</span></button>}

                                      { nextDraws?.result.length !== 0 && <h4  style={{marginTop: 40, color: "#5C27FE", }}>What are the rewards?</h4>}
                                </div>

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    >
                                    <Box sx={style} className='itemModal'>
                                        <div style={{backgroundColor: '#282B46', padding: 20, borderRadius:10}}>
                                        <div style={{width: '100%', textAlign: 'center'}}>
                                            <h5 style={{marginBottom: 10}}>Lottery</h5>
                                            <h6>How much ticket you want to use for this lottery?</h6>
                                        
                                            <TextField
                                                hiddenLabel
                                                id="outlined-basic"
                                                variant="outlined"
                                                size="small"
                                                onChange={(e)=>setNum(e.target.value)}
                                                sx={{width: 50, mt: 2}}
                                                />
                                        </div>
                                      
                                            <Box style={{marginTop: 20, textAlign: 'right'}}>
                                                <button className="action-btn" onClick={handleClose} style={{marginRight: 10}}><span style={{fontSize: 12}}>Cancel</span></button>
                                                                          
                                                <button className="action-btn" onClick={handleDraw} disabled={loading}><span style={{width: 110, fontSize: 12, textAlign: 'center'}}>{loading ? <CircularProgress size={15}/> : <>{"Confirm"}</>}</span></button>
                                            </Box>
                                        </div>
                                                                           
                                    </Box>
                                                                      
                                </Modal>
                               
                             { (priceInfo && nextDraws?.result.length !== 0) ?  <Swiper
                                    effect={"coverflow"}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            },
                                        500: {
                                            slidesPerView: 2,
                                            },
                                        700: {
                                            slidesPerView: 3,
                                        },
                                    }}
                                    coverflowEffect={{
                                    rotate: 30,
                                    stretch: 15,
                                    depth: 320,
                                    modifier: 1,
                                    slideShadows: false,
                                    }}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                  
                                    
              
                                    modules={[EffectCoverflow, Pagination , Navigation , Autoplay]}
                                    className="bannerSwiper"
                                >

                                    {
                                        priceInfo?.map((idx, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="card-box" >
                                                        <div className="top d-flex">
                                                            <span className="icon-logo-01"></span>
                                                            <div>
                                                                <h6>{idx.name}</h6>
                                                                <h6 className="price">{idx.price}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="image">
                                                                <img src={idx.image} alt="Cyfonii" />
                                                            </div>
                            
                                                            <div className="info d-flex">
                                                                {/* <img src={idx.content1} alt="Cyfonii" /> */}
                            
                                                                <div >
                                                                    <h6 className="name">{idx.content2}</h6>
                                                                    <p>{idx.content1}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                        ))
                                    }
                                      {
                                        priceInfo?.map((idx, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="card-box" >
                                                        <div className="top d-flex">
                                                            <span className="icon-logo-01"></span>
                                                            <div>
                                                                <h6>{idx.name}</h6>
                                                                <h6 className="price">{idx.price}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="image">
                                                                <img src={idx.image} alt="Cyfonii" />
                                                            </div>
                            
                                                            <div className="info d-flex">
                                                                {/* <img src={idx.content1} alt="Cyfonii" /> */}
                            
                                                                <div >
                                                                    <h6 className="name">{idx.content2}</h6>
                                                                    <p>{idx.content1}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                        ))
                                    }
       

                                </Swiper> : ""}
                                {(priceInfo2 && nextDraws?.result.length !== 0) ? <Roadmap data={priceInfo2}/> : ""}
                            </div>
                        </div>
                    </div>
                    <div ref={scrollRef}>
                      {myTickets ? <Speciality data={myTickets} uuid={user.id} /> : ""}
                    </div>
                   {/* {drawHistory ?<Finished data={drawHistory}/> : ""} */}
                   
                    {/* <Footer /> */}
                </section>
    );
}

export default Main;