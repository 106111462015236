import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
import { _deleteSession } from './authJwt';
// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	error: false,
	walletList: {},
};

const slice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
		
		getWalletListSuccess(state, action) {
			state.isLoading = false;
			state.walletList = action.payload;
		},
		
	},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions; 
////////////////////

export function getWalletList( ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.myWallets);
			dispatch(slice.actions.getWalletListSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

