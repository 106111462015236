import { Box, CircularProgress, FormControl, Icon, IconButton, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import closeFill from "@iconify-icons/eva/close-fill";
import configData from '../../config.json'
import { getMyTickets } from '../../redux/slices/lottery';
import blue from '../../assets/images/icon/blue.png'
import orange from '../../assets/images/icon/orange.png'
import grey from '../../assets/images/icon/grey.png'
import red from '../../assets/images/icon/red.png'
import green from '../../assets/images/icon/green.png'
import purple from '../../assets/images/icon/purple.png'
import yellow from '../../assets/images/icon/yellow.png'
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { _deleteSession } from '../../redux/slices/authJwt';

function Speciality(props) {
    const { user } = useAuth(); 
    const {data, uuid} = props;
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleOpen = (e) => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#070A29',
        borderRadius: 5,
        boxShadow: 24,
        p: 2,
      };

      const [colorId, setColorId] = React.useState('');

      const handleChange = (event) => {
        setColorId(event.target.value);
      };

      const handleSwitch = async () => {
        setLoading(true)
        if(colorId){
                try{
                const response = await axios.post(configData.SERVER_URL + configData.switchColor, {
                    ticketType: colorId
                });
                if(response && response.data.error === 0){
                enqueueSnackbar( response?.data?.message, {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    variant: "success",
                    action: (key) => (
                        <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                        </IconButton>
                    ),
                    });
                    dispatch(getMyTickets(uuid))
                }else if (response  && response.data.error !== 0) {
                    enqueueSnackbar( response?.data?.message, {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    variant: "error",
                    action: (key) => (
                        <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                        </IconButton>
                    ),
                    });
                } 
                }catch (err) {
                    if(err?.request?.status === 477){
                        _deleteSession();
                        navigate('/login', { replace: true });
                       }
                }
                
            }
            setLoading(false)
            handleClose()
      }

    return (
        <section className="speciality">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row" style={{justifyContent: 'center'}}>
                        <div className="col-12">
                            <div className="block-text center">
                                <h3 className="heading pd">Your Ticket List </h3>
                                    {/* <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer</p> */}
                            </div>
                        </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={grey} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Grey</h5>
                                        <p>{data.Grey} {data.Grey === 1 ? "Ticket" : "Tickets"}</p>
                                        <button className="action-btn" onClick={handleOpen}><span>Switch</span></button>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={blue} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Blue</h5>
                                        <p>{data.Blue} {data.Blue === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={green} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Green</h5>
                                        <p>{data.Green} {data.Green === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={orange} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Orange</h5>
                                        <p>{data.Orange} {data.Orange === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={purple} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Purple</h5>
                                        <p>{data.Purple} {data.Purple === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={red} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Red</h5>
                                        <p>{data.Red} {data.Red === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={yellow} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">Yellow</h5>
                                        <p>{data.Yellow} {data.Yellow === 1 ? "Ticket" : "Tickets"}</p>
                                    </div>
                                </div>
                     
                        
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >
                    <Box sx={style} className='itemModal'>
                        <div style={{display: 'flex', flexDirection: 'column',backgroundColor: '#282B46', padding: 20, borderRadius:10, justifyContent:'center',alignItems:'center'}}>
                        <div style={{width: 200, textAlign: 'center'}}>
                            <h5 style={{marginBottom: 10}}>Select Color</h5>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Color</InputLabel>
                             <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={colorId}
                                label="Color"
                                onChange={handleChange}
                            >
                                <MenuItem value={2}>Blue</MenuItem>
                                <MenuItem value={5}>Green</MenuItem>
                                <MenuItem value={6}>Orange</MenuItem>
                                <MenuItem value={7}>Purple</MenuItem>
                                <MenuItem value={3}>Red</MenuItem>
                                <MenuItem value={4}>Yellow</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                                      
                            <Box style={{marginTop: 20, textAlign: 'right'}}>
                                <button className="action-btn" onClick={handleClose} style={{marginRight: 10}}><span style={{fontSize: 12}}>Cancel</span></button>
                                                                          
                                <button disabled={loading} className="action-btn" onClick={handleSwitch}><span style={{width: 110, fontSize: 12, textAlign: 'center'}}>{loading ? <CircularProgress size={15}/> : <>{"Confirm"}</>}</span></button>
                            </Box>
                        </div>
                                                                           
                     </Box>
                                                                      
                </Modal>
            </section>

    );
}

export default Speciality;