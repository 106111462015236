import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import GamesCollection from '../components/GamesCollection';

import PageTitle from '../components/pagetitle/PageTitle';
import { getGameDetails, getGameList } from '../redux/slices/game';



function Games(props) {
    const dispatch = useDispatch();
    const { uuid } = useParams();
    const { gameList} = useSelector((state) => state.game);

    useEffect(() => {
        dispatch(getGameList());
        // if(uuid) {
            dispatch(getGameDetails(uuid));
        // }
        document.body.style.overflow = 'unset';
	}, [uuid]); 
   
   
    return (
        <div className='page-about wrapper'>

            <PageTitle title='Games' />

            <GamesCollection gameList={gameList}/>
            {/* <Footer /> */}
        </div>
    );
}

export default Games;