import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
import { _deleteSession } from './authJwt';
// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	error: false,
	profile: {},
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		  },
		// GET PROFILE
		getProfileSuccess(state, action) {
			state.isLoading = false;
			state.profile = action.payload;
		},		
	},
});

// Reducer
export default slice.reducer;

// Actions
// eslint-disable-next-line no-empty-pattern
export const {} = slice.actions;


export function getProfile(uuid) {


	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getProfile, {
				uuid: uuid,
			});
		
			dispatch(slice.actions.getProfileSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}





















