import React, { useState } from 'react';
import images from "../Images.json"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dataTeam from '../assets/fake-data/dataTeam';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';
import Team from '../components/team/Team';
import Collection from '../components/Collection';
import Testimonials from './Testimonials';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import About from './About';
import Contact from './Contact';
import {useSearchParams} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { _deleteSession,login} from '../redux/slices/authJwt';
function Home01(props) {
    const [categoryImage, setCategoryImage] = useState(images.categories.all)
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        document.body.style.overflow = 'unset';
        var tt=searchParams.get('ssoToken');
        console.log("tt",tt)
        if(tt!==null && tt!==undefined){
            _deleteSession()
            dispatch(login({tt})) 
        //     axios.post(configData.SERVER_URL + "/game/init",{
        //         ssoToken:tt
        //     })
        //     .then(function (response) {
        //       console.log("response",response)
        //    //   setSessus(response.data)
    
        // })
        }
    
    },[])

    return (
        <div className="home-1 wrapper">
            
            <Banner />
            <About />
{/*            
            <Testimonials data={dataTestimonials} />
            <Contact /> */}
            <Collection data={categoryImage} />
        </div>
        

    );
}

export default Home01;