import React , { useState , useEffect } from 'react';
import { ethers,providers } from "ethers"
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import logo2 from '../../assets/images/logo/Frame_12.png'
import metamask from '../../assets/images/icon/metamaskWallet.png'
import connect from '../../assets/images/icon/wallet-connect.svg'
import './styles.scss';
import useAuth from '../../hooks/useAuth';
import { Box, Menu, MenuItem, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dolar from '../../assets/images/icon/dollar.png'
import rekk from '../../assets/images/icon/REKK.png'
import reward from '../../assets/images/icon/REWARD_POINT_3.png'
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../pages/connectors";
import { getWalletList } from '../../redux/slices/wallet';
import { getProfile } from '../../redux/slices/user';
import axios from 'axios';
import Web3 from "web3";
import configData from '../../config.json';
import { getMyNumbers } from '../../redux/slices/game';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from "lodash"
const Header = () => {
    const { user, logout } = useAuth();
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);
    const { library,  account, activate, deactivate, error} = useWeb3React();
    const { walletList } = useSelector((state) => state.wallet);
    const { numbers } = useSelector((state) => state.game);
    const [name, setName] = useState()
    const [ENS, setENS] = useState()
    
    const [loading, setLoading] = useState(false)
    const [anchorE1, setAnchorE1] = useState(null);

    const handleClickE1 = (event) => {
        setAnchorE1(event.currentTarget);
      };
      const handleCloseE1 = () => {
        setAnchorE1(null);
      };

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
        if((index === 1 )|| (index === 4 ) || (index === 5) || (index === 6) || (index === 7)) {
            setMenuActive(false);
        }
        
    };

    const handleSignout = async () => {
        try {
          await logout();
        } catch (error) {
          console.error(error);
        }
      };
      var globalSignature



      // Set MM/walletConnect provider in localStorage
      const setProvider = (type) => { window.localStorage.setItem("provider", type) };
    
      // Unset MM/walletConnect provider in localStorage
      const refreshState = () => { window.localStorage.setItem("provider", undefined) };
    
      const disconnect = () => {
        refreshState();
        deactivate();
        setName()
      };
    
      
     useEffect(() => {
        document.body.style.overflow = 'unset';
        if(!_.isEmpty(user) && user?.displayName!==null){
          console.log("tutu",user)
            //dispatch(getProfile(user?.id));
          // dispatch(getWalletList());
            dispatch(getMyNumbers())
        }   
      }, [user]); 

      const connectMetaMask = async () => {
        let isCancelled = false;
        try {
            await activate(connectors.injected, () => {
              
              });
            // await activate(connectors.injected, () => {
            //     window.alert("MetaMask Connection Rejected");
            //     isCancelled = true;
            //   });
        } catch (err) {
            console.log(err)
            isCancelled = true;
        }
    
    
        if (!isCancelled) {
          setProvider("injected");
          console.log("MetaMask Connected Successfully");
      
          
         
        }
      }
    
      const connectWalletConnect = async () => {
        let isCancelled = false;
        console.log(connectors.walletConnect)
        await activate(connectors.walletConnect, () => {
            console.log("Wallet Connect Connection Rejected");
          isCancelled = true;
        });
    
        if (!isCancelled) {
          setProvider("walletConnect");
          console.log("Wallet Connect Connected Successfully");
          console.log({ account })
       
        
      
        }
      }
      useEffect(() => {
        if (error) {
        console.log(error)
        }
      }, [error]);
      useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) activate(connectors[provider]);
      }, [activate]);
    
      useEffect(() => {
        if (!account) return;
        if (!library) return;
        lookupENS().then(() => {
          console.log("DONE")
        }).catch(err => console.log('err', err))
    
      }, [account, library])

      
  const lookupENS = async () => {
   
    const provider = await library.provider;
    const web3Provider = new providers.Web3Provider(provider)
    console.log({ account })
    const _ens = await web3Provider.lookupAddress(account)
    console.log(_ens)
    if (_ens) setENS(_ens)
  }
  async function signMessageW() {
    console.log("CurrentAccount:",account)
  if(account===null){

    console.log("not connected")
    return
  }
  var retMessage=null
await  axios.post(configData.SERVER_URL + configData.signingAuth)
        .then(function (response) {
           
            if (response.data.error === 0) {

                setMessage(response.data.results);
                retMessage=response.data.results
            }
            else{
                console.log("error 58")
            }
    })

    
if(retMessage!==null){

    try {
     //   const provider = new ethers.providers.Web3Provider(connectors.walletConnect);
    //    const provider = new ethers.providers.Web3Provider(`https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e`);
     //   const provider = new ethers.providers.InfuraProvider(null, "https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e")
       //var provider=new Web3(`https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e`)
     // var provider=new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e'));
     const walletConnectProvider = new WalletConnectProvider({
        infuraId: "2100888ec1b94c45b2167c5fee056c1e" // Required
        // qrcode: true
      });
      var wc = walletConnectProvider;
      await walletConnectProvider.enable();
var web3 = new Web3(walletConnectProvider);

var rawMessage =retMessage;
var rawMessageLength = new Blob([rawMessage]).size;
var message = ethers.utils.toUtf8Bytes(
  "\x19Ethereum Signed Message:\n" + rawMessageLength + rawMessage
);

message = ethers.utils.keccak256(message);
var params = [
  // await this.signer.getAddress(),
  wc.accounts[0],
  message
];
var signature = await wc.connector.signMessage(params);

        globalSignature=signature

       
  await   axios.post(configData.SERVER_URL + configData.addWallet,{
    address:wc.accounts[0] ,
    message:rawMessage,
    globalSignature:globalSignature,
    provider:"walletconnect"

})
        .then(function (response) {
           console.log(response)
           setMessage(null)
    })


    } catch (err) {
        console.error(err);
        setMessage(null)
    }
  





}else{

    console.log("an error occured, try again later")


}
   

}

async function signMessage() {
    console.log("CurrentAccount:",account)
  if(account===null){

    console.log("not connected")
    return
  }
  var retMessage=null
await  axios.post(configData.SERVER_URL + configData.signingAuth)
        .then(function (response) {
           
            if (response.data.error === 0) {

                setMessage(response.data.results);
                retMessage=response.data.results
            }
            else{
                console.log("error 58")
            }
    })

    
if(retMessage!==null){

    try {

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log(provider);

        const signer = provider.getSigner();
        const signature = await signer.signMessage(retMessage);
        const address = await signer.getAddress();
        globalSignature=signature

       
  await   axios.post(configData.SERVER_URL + configData.addWallet,{
    address:address ,

    message:retMessage,
    globalSignature:globalSignature,
    provider:"injected"
})
        .then(function (response) {
           console.log(response)
           setMessage(null)
    })


    } catch (err) {
        console.error(err);
        setMessage(null)
    }
  





}else{

    console.log("an error occured, try again later")


}
   

}

    return (
        <header id="header_main" className={`header ${scroll ? '' : ''}`}>
            <div className="container big">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body">
                            <div className="header__logo">
                                <Link to="/">
                                    <img id="site-logo" src={logo2} alt='' style={{height: 30}}/>
                                </Link>
                            </div>

                            <div className="header__right">
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                          <li onClick={()=> handleDropdown(1)} className={`menu-item ${activeIndex === 1 ? 'active' : ''}`} >
                                            <Link to='/'>Home</Link>
                                          </li>
                                          <li onClick={()=> handleDropdown(2)} className={`menu-item 'menu-item-has-children' ${activeIndex === 2 ? 'active' : ''}`} >
                                          <Link to='#'>Partners <ExpandMoreIcon /></Link>
                                                   
                                                   <ul className="sub-menu">
                                                     <li className="menu-item"><NavLink to='/extracard'>Extracard</NavLink></li> 
                                                   </ul>
                                          </li>

                                          <li onClick={()=> handleDropdown(8)} className={`menu-item 'menu-item-has-children' ${activeIndex === 8 ? 'active' : ''}`} >
                                          <Link to='#'>Tribes <ExpandMoreIcon /></Link>
                                                   
                                                   {/* <ul className="sub-menu">
                                                     <li className="menu-item"><NavLink to='/extracard'>Extracard</NavLink></li> 
                                                   </ul> */}
                                          </li>

                                         {user && <li onClick={()=> handleDropdown(4)} className={`menu-item ${activeIndex === 4 ? 'active' : ''}`} >
                                            <Link to='/games'>Games</Link>
                                          </li>}
                                          {user && <li onClick={()=> handleDropdown(5)} className={`menu-item ${activeIndex === 5 ? 'active' : ''}`} >
                                            <Link to='/shop'>Shop</Link>
                                          </li>}
                                         { user && <li onClick={()=> handleDropdown(6)} className={`menu-item ${activeIndex === 6 ? 'active' : ''}`} >
                                            <Link to='/reward-point-factory'>Factory</Link>
                                          </li>}
                                          {user && <li onClick={()=> handleDropdown(7)} className={`menu-item ${activeIndex === 7 ? 'active' : ''}`} >
                                            <Link to='/lottery'>Lottery</Link>
                                          </li>}
                                          {/* {!user &&
                                          <li onClick={()=> handleDropdown(9)} className={`menu-item} ${activeIndex === 9 ? 'active' : ''}`}>
                                            <Link to='/register'>Register</Link>
                                          </li>} */}

                                      {  user && <li onClick={()=> handleDropdown(8)} className={`menu-item 'menu-item-has-children' ${activeIndex === 8 ? 'active' : ''}`} 
                                                
                                                >
                                                    <Link to='#'>Profile <ExpandMoreIcon /></Link>
                                                   
                                                        <ul className="sub-menu">
                                                          <li className="menu-item"><NavLink to='/profile'>My Profile</NavLink></li>
                                                          <li className="menu-item"><NavLink to='/deposit'>Deposit</NavLink></li>  
                                                        </ul>
                                                    
                                                    
                                                </li>}
                                    </ul>
                                    <div className="header__action-nav" >
                                {user ? <button onClick={handleSignout} className="action-btn"><span>Logout</span></button>
                                :
                                <Link to="/login" className="action-btn"><span>Login</span></Link>}


                                {user && 
                                <button  className="action-btn connectWalletBtn" onClick={handleClickE1}>
                                  <span>{account!==undefined ? account.substring(0, 5)+"..."+account.substring(39, 42) : ("Connect Wallet") }</span>
                                </button> }

                                <Menu
                                    anchorEl={anchorE1}
                                    open={Boolean(anchorE1)}
                                    onClose={handleCloseE1}
                                    MenuListProps={{ disablePadding: true }}
                                    disableScrollLock={true}
                                    className= 'menu'
                                    sx={{zIndex: 9999999}}
                                    >
                                    <Box sx={{minWidth: 170, backgroundColor:"#fff"}}>
                                        <MenuItem onClick={handleCloseE1} className="menuItem" sx={{p:2,borderBottomColor:"gray", borderBottomWidth:1, borderBottomStyle: 'solid'}} >
                                            <div onClick={connectMetaMask} style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                                            <img src={metamask} alt="" style={{width: 20, height: 20, marginRight: 10}}/>
                                           <h6 style={{fontSize: 12, color: '#000'}} >METAMASK</h6> 
                                            </div>
                                          
                                        
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseE1} sx={{p: 2}}>
                                            <div onClick={connectWalletConnect} style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                                                <img src={connect} alt="" style={{width: 20, height: 20, marginRight: 10}}/>
                                                <h6 style={{fontSize: 12, color: '#000'}} >WALLETCONNECT</h6>
                                            </div>
                                           
                                    
                                        </MenuItem>
                                    
                                        </Box>
                                </Menu>
                            </div>
                                </nav>
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            </div>

                           {user && <div style={{display: 'flex'}} className="numbers">
                              <div style={{marginRight: 20, textAlign: "center"}}>
                                <img src={dolar} alt="" style={{height: 20}}/>
                                <h6 style={{fontSize: 10, marginTop: 5}}>{Math.round(numbers?.result?.dollars * 100) / 100}</h6>
                              </div>
                              <div style={{marginRight: 20, textAlign: "center"}}>
                                <img src={rekk} alt="" style={{height: 20}}/>
                                <h6 style={{fontSize: 10, marginTop: 5}}>{Math.round(numbers?.result?.rekk * 100) / 100}</h6>
                              </div>
                              <div style={{ textAlign: "center"}}>
                                <img src={reward} alt="" style={{height: 20}}/>
                                <h6 style={{fontSize: 10, marginTop: 5}}>{Math.round(numbers?.result?.rpPoints * 100) / 100}</h6>
                              </div>
                            </div>}

                            <div className="header__action">
                                {user ? <button onClick={handleSignout} className="action-btn"><span>Logout</span></button>
                                :
                                <Link to="/login" className="action-btn"><span>Login</span></Link>}

                               
                               { user && <button  className="action-btn" style={{marginLeft: 10}} onClick={handleClickE1}><span>{account!==undefined ? account.substring(0, 5)+"..."+account.substring(39, 42) : ("Connect Wallet") }</span></button>}


                                {/* <button  className="action-btn" style={{marginLeft: 10}} onClick={handleClickE1}><span>Connect Wallet</span></button> */}

                                <Menu
                                    anchorEl={anchorE1}
                                    open={Boolean(anchorE1)}
                                    onClose={handleCloseE1}
                                    MenuListProps={{ disablePadding: true }}
                                    disableScrollLock={true}
                                    className= 'menu'
                                    >
                                    <Box sx={{minWidth: 170, backgroundColor:"#fff"}}>
                                        <MenuItem onClick={handleCloseE1} className="menuItem" sx={{p:2,borderBottomColor:"gray", borderBottomWidth:1, borderBottomStyle: 'solid'}} >
                                            <div onClick={connectMetaMask} style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                                            <img src={metamask} alt="" style={{width: 20, height: 20, marginRight: 10}}/>
                                           <h6 style={{fontSize: 12, color: '#000'}} >METAMASK</h6> 
                                            </div>
                                          
                                        
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseE1} sx={{p: 2}}>
                                            <div onClick={connectWalletConnect} style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}>
                                                <img src={connect} alt="" style={{width: 20, height: 20, marginRight: 10}}/>
                                                <h6 style={{fontSize: 12, color: '#000'}} >WALLETCONNECT</h6>
                                            </div>
                                           
                                    
                                        </MenuItem>
                                    
                                        </Box>
                                </Menu>
                            </div>

                          
                            

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
    );
}

export default Header;