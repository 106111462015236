import React, { useEffect } from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import configData from '../config.json'

import Main from '../components/game/Main';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameList } from '../redux/slices/game';
import Footer from '../components/footer/Footer';
import './game.scss'



function Game(props) {
    const dispatch = useDispatch();
    const { gameList} = useSelector((state) => state.game);
    const { uuid } = useParams();
    const [gameDetails, setGameDetails] = useState()

    useEffect(() => {
        if(!uuid) return;
        dispatch(getGameList());
        if(gameList.result){
            setGameDetails(gameList?.result.find(x => x.uuid === uuid))
        }
        
		
	}, [uuid]); 
   
   
    return (
        <div className='game wrapper'>

            <PageTitle title='Scratch Game' />

            <Main gameDetails={gameDetails}/>
            {/* <Footer /> */}
        </div>
    );
}

export default Game;