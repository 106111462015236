import React from 'react';

import Main from '../components/timeout/Main';
import Footer from '../components/footer/Footer';


function Timeout({ authenticate}) {
  
    return (
        <div className='home-3 wrapper'>

        <Main authenticate={authenticate}/>

        {/* <Footer /> */}

        </div>
    );
}

export default Timeout;