import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const injected = new InjectedConnector({
  supportedChainIds: [56]
});


const ALL_SUPPORTED_CHAIN_IDS = [56]
const INFURA_NETWORK_URLS = {
  // 1: `https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e`,
  // 3: `https://ropsten.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  // 4: `https://rinkeby.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  // 5: `https://goeril.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  56:'https://nd-315-986-486.p2pify.com/23fc66efc086749f175aa6a836692397'

}


const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: INFURA_NETWORK_URLS,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
};