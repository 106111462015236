
import React , { useState} from 'react';

import {  useNavigate } from 'react-router-dom';


import 'swiper/swiper.min.css';
// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';


function GamesCollection({gameList}) {
   
    const navigate = useNavigate();
  


    const handleCardClick = (e) => {
        navigate(`/game/${e.uuid}`);
    }
    return (
        <section className="project s2">
            <div className="shape right"></div>
            <div className="container">
                <div style={{display:'flex', flexWrap: 'wrap',justifyContent: 'center'}}>

                                {
                                    gameList.result && gameList.result?.map(idx => (
                                        <div key={idx.uuid} style={{textAlign: 'center', margin: 15}}>
                                            <button to={`/game/${idx.uuid}`} onClick={() => handleCardClick(idx)} style={{backgroundColor: 'transparent', border: 'none'}}>
                                                <div className="project-boxG">
                                                   
                                                        <div className="image">
                                                            <div >
                                                                <img src={idx.img} alt="Cyfonii" />
                                                            </div>
                                                        </div>
                                                 
                                                   
                                                    <div className="content">
                                                    <div style={{display: 'flex'}}>
                                                        <div>
                                                            <img src={idx.img} alt="" style={{width: 45, height: 45, marginRight: 10}}/>
                                                        </div>
                                                        <div>
                                                            <h1 style={{fontSize: 20}}>{idx.title}</h1>
                                                            <h3 style={{fontSize:12}}>{idx.type}</h3>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </button>
                                            </div>
                                       
                                    ))
                                }
                               
                         

                

                </div>
            </div>
        </section>
    );
}

export default GamesCollection;