import React , {useState} from 'react';

import img1 from '../assets/images/dummy_image/3.jpg'
import img2 from '../assets/images/dummy_image/4.jpg'
import img3 from '../assets/images/dummy_image/1.jpg'
import img4 from '../assets/images/dummy_image/7.jpg'
import img5 from '../assets/images/dummy_image/2.jpg'
import nft from '../assets/images/nft.png'



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : nft,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img : img2,
        //     class: 'img2'
        // },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        // {
        //     id: 4,
        //     img : img4,
        //     class: 'img4'
        // },
        {
            id: 5,
            img : img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            // subheading: 'About us',
            heading: 'Adding Utilities to NFT Collections',
            desc1: 'VIP customers or guests from our partnering projects receive exclusive bonuses, privileges, and further opportunities to earn Reward Points. ' ,
            desc2 :'Our inaugural partnering project is ExtraCard: ',
            desc3 :'ExtraTribes is currently in Open Beta.',
            desc4 :'Welcome and make yourself at home!',
            desc5 :'The Oracle',
        }
    )
    return (
        <section className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12" style={{marginBottom: 50}}>
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <h6 className="mb-17">{dataBlock.desc1}</h6>
                                    <h6 className="mb-26">{dataBlock.desc2}<a href="https://extracard.io/">extracard.io</a></h6>
                                    <h6 className="mb-17">{dataBlock.desc3}</h6>
                                    <h6 className="mb-17">{dataBlock.desc4}</h6>
                                    <h6 className="mb-17">{dataBlock.desc5}</h6>
                                    {/* <Button link='/about' title='More About Us' /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;