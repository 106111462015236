import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
import { _deleteSession } from './authJwt';

// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	error: false,
	gameList: {},
	gameDetails: {},
	posibilities: {},
	gameResult: {},
	endGame: {},
	affiliations: {},

	myFactory: {},
	rpBooster: {},

	numbers: {}

};

const slice = createSlice({
	name: 'game',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
		
		getGameListSuccess(state, action) {
			state.isLoading = false;
			state.gameList = action.payload;
		},
		getGameDetailsSuccess(state, action) {
			state.isLoading = false;
			state.gameDetails = action.payload;
		},
		getPosibilitiesSuccess(state, action) {
			state.isLoading = false;
			state.posibilities = action.payload;
		},

		launchGameSuccess(state, action) {
			state.isLoading = false;
			state.gameResult = action.payload;
		},

		finishGameSuccess(state, action) {
			state.isLoading = false;
			state.endGame = action.payload;
		},

		clearGameSuccess(state, action) {
			state.isLoading = false;
			state.endGame = {};
			state.gameResult = {};
			
		},
		getAffiliationsSuccess(state, action) {
			state.isLoading = false;
			state.affiliations = action.payload;
		},

		getmyFactorySuccess(state, action) {
			state.isLoading = false;
			state.myFactory = action.payload;
		},
		getRPBoosterSuccess(state, action) {
			state.isLoading = false;
			state.rpBooster = action.payload;
		},
		getNumbersSuccess(state, action) {
			state.isLoading = false;
			state.numbers = action.payload;

		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const {setTribeCode, setAffCode} = slice.actions; 
////////////////////

export function getGameList( ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getGameList);
			dispatch(slice.actions.getGameListSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function getGameDetails(uuid ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.gameFromList, {
				gameUUID: uuid
			});
			dispatch(slice.actions.getGameDetailsSuccess(response.data.result));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function getPosibilities(uuid, gameId) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getPosibilities, {
				uuid: uuid,
				gameId: gameId
			});
			dispatch(slice.actions.getPosibilitiesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function launchGame(uuid,gameUUID) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.launchGame, {
				uuid: uuid,
				gameUUID:gameUUID,
			});
			dispatch(slice.actions.launchGameSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function finishGame( uuid ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.endGame, {
				uuid: uuid,
			});
			dispatch(slice.actions.finishGameSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}
export function clearGame(  ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			
			dispatch(slice.actions.clearGameSuccess());
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}
export function getAffiliations() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getAffiliations);
			dispatch(slice.actions.getAffiliationsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function getMyFactory() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getmyFactory);
			dispatch(slice.actions.getmyFactorySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}
export function getRPBooster() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getRPBooster);
			dispatch(slice.actions.getRPBoosterSuccess(response.data));
				} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function getMyNumbers() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getmyNumbers);
			dispatch(slice.actions.getNumbersSuccess(response.data));

		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}