import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {
  Alert,
} from "@mui/material";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import configData from "../../config.json";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { getMyNumbers } from '../../redux/slices/game';
import { useNavigate } from "react-router-dom";
import { _deleteSession } from "../../redux/slices/authJwt";


export default function DepositEuro() {
    const { user } = useAuth(); 
    const navigate = useNavigate();
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [amount, setAmount] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    
    const cardStyle = {
      style: {
        base: {
          color: "#fff",
          fontFamily: "Bios",
          fontSize: "14px",
          "::placeholder": {
            color: "rgba(255, 255, 255, 0.4)",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
    const handleChange = (event) => {
      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      setDisabled(event.empty);
      setError(event.error ? event.error.message : "");
    };
    const handleSubmit = async (ev) => {
      ev.preventDefault();
      setProcessing(true);
      let paymentIntents = null;
      if (Number(amount) == 0) {
        setError("Please complete amount");
        return;
      }
      try {
        /* Stripe allow only integer value in price so need to change your price (amount) into cent by (*100 ) */
        const stripeAmount = parseInt(amount * 100);
  
        const res = await axios.post(
          configData.SERVER_URL + configData.createPaymentIntent,
          {
            items: [{ userId: user.id, amount: stripeAmount }],
          }
        );
        if (!res || !res.data) {
          throw new Error("Error from payment server");
        }
  
        /*  if (res.data.error !== 0) {
          throw new Error(res.data.message);
        } */
        paymentIntents = res.data;
  
        if (paymentIntents) {
          const cardEl = elements.getElement(CardElement);
          const clSecret = paymentIntents.clientSecret;
  
          const payload = await stripe.confirmCardPayment(clSecret, {
            payment_method: {
              card: cardEl,
            },
          });
  
          if (payload.error) {
            throw new Error(`Payment failed : ${payload.error.message}`);
          }
          setSucceeded(true);
          setError(null);
          setProcessing(false);
          setAmount(0);
          cardEl.clear();
          setTimeout(() => {
            setSucceeded(false);
          }, 2000);
        }
        dispatch(getMyNumbers());
        setProcessing(false);
      } catch (error) {
        console.log(error);
        if(error?.request?.status === 477){
          _deleteSession();
          navigate('/login', { replace: true });
         }
        setProcessing(false);
        setError(error.message);
      }
    };
  return (
    <div>
       <form id="payment-form" onSubmit={handleSubmit}>
      <div>
        <h5 style={{marginBottom: 10}}>PAYMENT DETAILS</h5>
        <h6>Enter your details below to add money!</h6>
        <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
          <input
            className="stripeInput"
            type="text"
            value={amount}
            placeholder="Amount"
            onChange={(e) => setAmount(e.target.value.replace(/[^0-9,.]/g, ""))}
          />
          <input
            className="stripeInput"
            type="text"
            onFocus={(e) => (e.target.value = "")}
            placeholder="Card Holder"
          />
           <CardElement
              className='stripeInput'
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
        </div>
        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}
        {/* Show a success message upon completion */}
        {succeeded && (
          <Alert severity="success">
            { "Payment succeeded"}
          </Alert>
        )}
        <div className="col">
          <button
            type="submit"
            className="action-btn"
            disabled={processing || disabled || succeeded}
          >
            <span style={{width: 130}}>
              <div className="spinner" id="spinner"></div>
              {processing ? (
               
                  <CircularProgress size={15} />
             
              ) : (
                <>{"Pay now"}</>
              )}
            </span>
           
          </button>
        </div>
      </div>
    </form>
    </div>
  )
}
