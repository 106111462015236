import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
import { _deleteSession } from './authJwt';
// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	error: false,
	myTickets: {},
	draw: {},
	drawHistory: {},
	nextDraws: {}
};

const slice = createSlice({
	name: 'lottery',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
		
		getMyTicketsSuccess(state, action) {
			state.isLoading = false;
			state.myTickets = action.payload;
		},
		drawParticipationsSuccess(state, action) {
			state.isLoading = false;
			state.draw = action.payload;
		},
		getDrawHistorySuccess(state, action) {
			state.isLoading = false;
			state.drawHistory = action.payload;
		},
		getNextDrawsSuccess(state, action) {
			state.isLoading = false;
			state.nextDraws = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions; 
////////////////////

export function getMyTickets(uuid ) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getMyTickets,{
				drawUUID: uuid
			});
			dispatch(slice.actions.getMyTicketsSuccess(response.data.result.myTickets));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}

export function drawParticipation(uuid, num) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.drawParticipation,{
				drawUUID: uuid,
				numbersOfTicket: num
			});
			dispatch(slice.actions.getMyTicketsSuccess(response.data.result.myTickets));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}
export function getDrawHistory() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.drawHistory);
			dispatch(slice.actions.getDrawHistorySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}
export function getNextDraws() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.nextDraws);
			dispatch(slice.actions.getNextDrawsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
			if(error?.request?.status === 477){
				_deleteSession();
				window.location.replace('/login');
               }
		}
	};
}