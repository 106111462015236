import React from 'react';
import dataCard from '../assets/fake-data/data-card';
import Main from '../components/lottery/Main';



function Lottery(props) {
    

    return (
        <div className='home-2 wrapper'>
                <Main data={dataCard} />

               
        </div>
    );
}

export default Lottery;