
import React , { useState} from 'react';


import { Navigation , Pagination   } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


function Collection(props) {
const {data} = props
    const [dataBlock] = useState(
        {
            subheading: 'Our Speciality',
            heading: 'Communities and projects supported',
            
        }
    )
    
    return (
        <section className="project">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            {/* <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6> */}
                            <h3 className="heading" >{dataBlock.heading}</h3>
                        </div>

                        <Swiper
                                className="project-swiper"
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                loop={true}
                                modules={[ Navigation , Pagination ]}
                                navigation
                                pagination={{
                                    clickable: true,
                                  }}
                                
                            >

                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <div className="swiper-slide">
                                                <div className="project-box">
                                                   
                                                        <div className="image">
                                                            <div >
                                                                <img src={idx.src} alt="Cyfonii" />
                                                            </div>
                                                        </div>
                                                 
                                                   
                                                    <div className="content">
                                                    <div style={{display: 'flex'}}>
                                                        <div>
                                                            <img src={idx.user.src} alt="" style={{width: 45, height: 45, marginRight: 10}}/>
                                                        </div>
                                                        <div>
                                                            <h1 style={{fontSize: 20}}>{idx.user.name}</h1>
                                                            <h3 style={{fontSize:12}}>{idx.user.job}</h3>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                               
                            </Swiper>

                    </div>

                </div>
            </div>
        </section>
    );
}

export default Collection;