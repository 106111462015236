import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/dummy_image/8.jpg'
import img2 from '../../assets/images/dummy_image/3.jpg'
import img3 from '../../assets/images/dummy_image/1.jpg'
import img4 from '../../assets/images/dummy_image/7.jpg'
import img5 from '../../assets/images/dummy_image/2.jpg'


function Banner(props) {
    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                <h3 className="heading">Join ExtraTribes    <br />
                                for <span className="s1 arlo_tm_animation_text_word">free</span> <br />
                                       </h3>
                                    <h6 className="desc">Start earning Reward Points. Accumulate enough points and redeem them for valuable NFTs or Privileges in our Rewards Shop!</h6>
                                    <h6 className="desc">ExtraTribes also serves as a bridge between Web3 communities:</h6>
                                    <h6 className="desc">As a Holder in one of our featured projects, you'll enjoy more opportunities to earn Reward Points, such as receiving additional daily scratch tickets. This encourages exploration of other referenced projects, fostering communal growth. Our first featured project is Time Out Genesis: <a href="https://discord.gg/sJxBytsYyz">See Discord Server</a></h6>

                                    {/* <Link to="/login" className="action-btn"><span>Get Connected</span></Link> */}
                                </div>

                                <div className="pay">
                                    <h6></h6>

                                    <div className="list">
                                        <p></p>

                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image" style={{maxWidth: 500, maxHeight: 500}}>
                                <img src={img1} alt="" />
                                </div>

                                {/* <div className="price">
                                    <div className="icon">
                                       
                                    </div>
                                    <div className="content">
                                        <p></p>
                                        <h5></h5>
                                    </div>
                                </div>

                                <div className="owner">
                                    <div className="image">
                                        
                                    </div>
                                    <div className="content">
                                        <h5></h5>
                                        <p></p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;