import React, { useEffect ,useState} from 'react';
import './RewardFactory.scss'
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
import Footer from '../components/footer/Footer';
import './Profile.scss'
import { getMyFactory, getMyNumbers, getRPBooster } from '../redux/slices/game';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Icon, IconButton, Modal } from '@mui/material';
import closeFill from "@iconify-icons/eva/close-fill";
import configData from '../config.json'
import axios from 'axios';
import line from '../assets/images/background/line-2.png'
import { _deleteSession } from '../redux/slices/authJwt';

function RewardFactory(props) {
    const dispatch = useDispatch(); 
    const { myFactory, rpBooster } = useSelector((state) => state.game);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [clickedItem, setClickedItem] = useState()
    const [priceType, setPriceType] = useState()
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

     useEffect(() => {
        document.body.style.overflow = 'unset';
            dispatch(getMyFactory()); 
            dispatch(getRPBooster())
      }, []); 
      const handleOpen = (e) => {
        setOpen(true)
        setClickedItem(e)
        console.log(e)
    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#070A29',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
      };

      const handleBuy = async () => {
        setLoading(true)
        if(clickedItem){
         try{
           const response = await axios.post(configData.SERVER_URL + configData.buyRPBooster, {
            boosterId: clickedItem.rid ,
            priceType: priceType
           });
           if(response && response.data.error === 0){
            enqueueSnackbar( response?.data?.message, {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "success",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
            
           }else if (response  && response.data.error !== 0) {
             enqueueSnackbar( response?.data?.message, {
               anchorOrigin: {
                 vertical: "top",
                 horizontal: "left",
               },
               variant: "error",
               action: (key) => (
                 <IconButton size="small" onClick={() => closeSnackbar(key)}>
                   <Icon icon={closeFill} />
                 </IconButton>
               ),
             });
           } 
         }catch (err) {
          if(err?.request?.status === 477){
            _deleteSession();
            navigate('/login', { replace: true });
           }
         }
         
        }
        dispatch(getMyFactory()); 
        dispatch(getRPBooster())
        dispatch(getMyNumbers())
        setLoading(false);
        handleClose(); 
    }

    const handleInvest = async () => {
      setLoading2(true)
      
       try{
         const response = await axios.post(configData.SERVER_URL + configData.factoryInvest);
         if(response && response.data.error === 0){
          enqueueSnackbar( response?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              variant: "success",
              action: (key) => (
                <IconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </IconButton>
              ),
            });
          
         }else if (response  && response.data.error !== 0) {
           enqueueSnackbar( response?.data?.message, {
             anchorOrigin: {
               vertical: "top",
               horizontal: "left",
             },
             variant: "error",
             action: (key) => (
               <IconButton size="small" onClick={() => closeSnackbar(key)}>
                 <Icon icon={closeFill} />
               </IconButton>
             ),
           });
         } 
       }catch (err) {
        if(err?.request?.status === 477){
          _deleteSession();
          navigate('/login', { replace: true });
         }
       }
       
       dispatch(getMyFactory()); 

      setLoading2(false);
  
  }

    return (
        <div className='page-advisor wrapper'>

        <section className="page-title">
                {/* <div className="shape"></div> */}
                {/* <div className="shape right s3"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title__body">
                                <div className="page-title__main">
                                    <h4 className="title">Factory</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team s2 ">
            <img src={line} alt="" className="img-line" />
              <div>
                {myFactory?.result && <div className="reward-content">
                  <div>
                    <div>
                        <h6 className='sub-heading'><span style={{fontSize: 22}}>Weekly Revenue : {Math.round(myFactory?.result?.WeeklyRevenue * 10000) / 10000}</span> </h6>
                    </div> 
                    <div>
                      <h6 className='sub-heading'><span style={{fontSize: 22}}>Total Boost : {myFactory?.result?.totalBoost}</span></h6>
                    </div>       
                    <div>
                      <h6 className='sub-heading'><span style={{fontSize: 22}}>Total Invest : {myFactory?.result?.totalInvest}</span></h6>
                    </div>   
                    </div>
                       <button className='action-btn' onClick={handleInvest} disabled={myFactory?.result?.remainingTickets === 0}><span>Invest all my {myFactory?.result?.remainingTickets} remaining tickets</span></button>     
                </div>}
                </div>
            </section>
            <section className="nft">
                <div className="container">
                    <div className="row" style={{justifyContent: 'center'}}>
            {
                            rpBooster?.result?.map(idx => (
                                <div key={idx.id} className="col-xl-3 col-md-6">
                                    <div className="nft-item">                               
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="Cyfonii" style={{backgroundColor: '#070A29'}}/></Link>
                                            
                                            
                                        </div>
                                        <div className="card-title">
                                            <Link to="#" className="h5">{idx.name}</Link>
                                        </div>
                            
                                        <div className="card-bottom " style={{disply: 'flex',flexDirection: 'column'}}>
                                            <h6 style={{fontSize: 12, color: 'gray', marginBottom: 5}}>Total bought : {idx.nb}</h6>
                                            <h6>Buy for :</h6>
                                            <div style={{display: 'flex',justifyContent: 'space-between', marginTop: 10}}>
                                              <div className="button-place-bid">
                                                  <button className="action-btn" 
                                                  disabled={idx.isLevelAccessible === 0}
                                                  onClick={() => {
                                                    handleOpen(idx)
                                                    setPriceType(2)}}
                                                    ><span>{idx.rekkPrice} KARE</span></button>
                                              </div>
                                              <div className="button-place-bid">
                                                  <button className="action-btn" 
                                                   disabled={idx.isLevelAccessible === 0}
                                                  onClick={() =>{ 
                                                  handleOpen(idx)
                                                  setPriceType(1)}}><span>{idx.dollarPrice} Dollars</span></button>
                                              </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                          <Modal
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        aria-labelledby="modal-modal-title"
                                                                        aria-describedby="modal-modal-description"
                                                                    >
                                                                        <Box sx={style} className='itemModal'>
                                                                            <div style={{backgroundColor: '#282B46', padding: 20, borderRadius:10}}>
                                                                                <h5 style={{marginBottom: 10}}>Are you sure you want to buy {clickedItem?.name} for {priceType === 1 ? clickedItem?.dollarPrice + ' dollars' : priceType === 2 ? clickedItem?.rekkPrice + ' KARE': ''}?</h5>
                                                                  
                                                                        <Box style={{marginTop: 20, textAlign: 'right'}}>
                                                                            <button className="action-btn" onClick={handleClose} style={{marginRight: 10}}><span style={{fontSize: 12}}>Cancel</span></button>
                                                                          
                                                                            <button className="action-btn" onClick={handleBuy} disabled={loading} ><span style={{width: 110, fontSize: 12, textAlign: 'center'}}>{loading ? <CircularProgress size={15}/> : <>{"Confirm"}</>}</span></button>
                                                                        </Box>
                                                                            </div>
                                                                           
                                                                        </Box>
                                                                      
                                                                    </Modal>
                        </div>
                        </div>
                        </section>
            {/* <Footer /> */}
        </div>
    );
}

export default RewardFactory;
