import React from 'react';

import Main from '../components/extracard/Main';
import Footer from '../components/footer/Footer';


function Extracard(props) {
    return (
        <div className='home-3 wrapper'>

        <Main />

        {/* <Footer /> */}

        </div>
    );
}

export default Extracard;