import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import img from '../../assets/images/layouts/avt-01.png'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { useDispatch, useSelector } from 'react-redux';
import configData from '../../config.json'
import axios from 'axios';
import dummy from '../../assets/images/dummy_image/2.jpg'
import { Box, CircularProgress, Icon, IconButton, Modal, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import closeFill from "@iconify-icons/eva/close-fill";
import './styles.scss'
import useAuth from '../../hooks/useAuth';
import { getProfile } from '../../redux/slices/user';
import _ from "lodash"
import { _deleteSession } from '../../redux/slices/authJwt';

Project3.propTypes = {
    data: PropTypes.array
};

function Project3() {
    const { user } = useAuth(); 
    const { shopContent} = useSelector((state) => state.shop);
    const [clickedTab, setClickedTab] = useState()
    const [clickedItem, setClickedItem] = useState()
    const [shopList, setShopList] = useState()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const coutingUpdData = useRef(null);
    const [data, setData] = useState([])
    const [search, setSearch] = useState('');
    const [searchValue, setSearchValue] = useState()
	const [param, setParam] = useState({
		page: 0,
		pageSize: 10,
        shopId: clickedTab,
        filtered: searchValue
	});

    useEffect(() => {
        document.body.style.overflow = 'unset';
        if(!_.isEmpty(user) && user?.displayName!==null){
            
            console.log("tata",user)
           
            dispatch(getProfile(user.id));
        }   
      }, [user]); 

    const handlePageChange = useCallback((event, newPage) => {
		const newParam = { ...param };
		newParam.page = newPage;
		setParam(newParam);
	}, [param]);
  
	const handlePageSizeChange = useCallback((event) => {
		const newParam = { ...param };
		newParam.pageSize = parseInt(event.target.value, 10);
    newParam.page = 0
		setParam(newParam);
	}, [param]);

    const handleOpen = (e) => {
        setOpen(true)
        setClickedItem(e)
        console.log(e)
    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#070A29',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
      };

    useEffect(() =>{    
       
        const init = async () => {
            try {
                const response = await axios.post(configData.SERVER_URL + configData.getShops);
                if(response && response.data.error === 0){
                    setShopList(response.data)
                    setClickedTab(response.data.result[0].id)
                  }
            } catch (error) {
                if(error?.request?.status === 477){
                    _deleteSession();
                    navigate('/login', { replace: true });
                   }
            }
        }
        init()
    },[ ])

    useEffect(() => {
		if (!param) return;
		const init = async () => {
			// setLoadingx(true);
			const res = await axios.post(configData.SERVER_URL + configData.shopContent, param);
			if (res && res.data) {
				setData(res.data);
			}
			coutingUpdData.current = 0;
			// setLoadingx(false);
		};
		init();
	}, [param]);

    useEffect(() => {
		const timeout = setTimeout(() => {
			coutingUpdData.current = 0;
		}, 800);
		return () => {
			clearTimeout(timeout);
		};
	}, [param]);

    useEffect(() =>{    
        setParam({...param, shopId: clickedTab, filtered: searchValue})
    },[ clickedTab, searchValue])

    const handleTabClick = (e) => {
        setClickedTab(e)
    }
    
    const handleBuy = async () => {
        setLoading(true)
        if(clickedItem){
         try{
           const response = await axios.post(configData.SERVER_URL + configData.buyItem, {
            shopId: clickedTab ,
            itemUUID: clickedItem.uuid
           });
           if(response && response.data.error === 0){
            enqueueSnackbar( response?.data?.message, {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                variant: "success",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });
            
           }else if (response  && response.data.error !== 0) {
             enqueueSnackbar( response?.data?.message, {
               anchorOrigin: {
                 vertical: "top",
                 horizontal: "left",
               },
               variant: "error",
               action: (key) => (
                 <IconButton size="small" onClick={() => closeSnackbar(key)}>
                   <Icon icon={closeFill} />
                 </IconButton>
               ),
             });
           } 
         }catch (err) {
            if(err?.request?.status === 477){
                _deleteSession();
                navigate('/login', { replace: true });
               }
         }
         
        }
        setLoading(false);
        handleClose(); 
    }

    if(!user){
        return <Navigate to="/login" replace/>;
    }
    return (
        <section className="nft">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="faq__main flat-tabs">

                            <Tabs>
                                <TabList className='menu-tab'>
                                    {
                                        shopList?.result?.map(idx => (
                                            // <div onClick={() => handleTabClick(idx.id)}>
                                                <Tab className='fs-14 h6' key={idx.id} onClick={() => handleTabClick(idx.id)}>{idx.name}</Tab>
                                            // </div>
                                        
                                        ))
                                    }
                                    
                                </TabList>

                                { data.code === 1 &&
                                    (
                                    <div style={{display: 'flex', width: '100%', height: '100%',alignItems: 'center', justifyContent: 'center', minHeight: '30vh'}}>
                                            <h5>{data.message}</h5>
                                    </div>
                                    )
                            }
                                {shopList?.result?.lenght>0 ??
                                           (
                                            <div className="filter">
                                            <form action="#" className="widget-search">
                                            <input type="text" placeholder="Search item here..."  value={search}   onChange={(e) => setSearch(e.target.value)} />
                                            <div className="btn-search" onClick={() => setSearchValue(search)} style={{cursor: 'pointer'}}>
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9.7659" cy="9.76639" r="8.98856" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                                    <path d="M16.0176 16.4849L19.5416 19.9997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </div>
                                        </form>
                                        
                                {/* <div id="category" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">All categories</Link>
                                    <ul className="">
                                        <li><span>All Items</span></li>
                                        <li><span>Bundles</span></li>
                                    </ul>
                                </div>
                                <div id="buy" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">Buy now</Link>
                                    <ul className="">
                                        <li><span>All Items</span></li>
                                        <li><span>Bundles</span></li>
                                    </ul>
                                </div>
                                <div id="item" className="dropdown">
                                    <Link to="#" className="btn-selector nolink ">All item</Link>
                                    <ul className="">
                                        <li><span>All Items</span></li>
                                        <li><span>Bundles</span></li>
                                    </ul>
                                </div> */}
                            </div>
                                           )

                                }
                         
                            <TableContainer className='table-container'>
                                <Table className='table'>
                                    <TableBody className='table-body'>

                                    {
                                           shopList?.result?.map(idx => (
                                                <TabPanel key={idx.id} className='content-tab'>
                                                    <div className="content-inner">
                                                        <div className="flat-accordion row">
                                                        {
                                                                data?.result?.map(idx => (
                                                                    <div key={idx.id} className="col-xl-4 col-md-6">
                                                                        <div className="nft-item">                               
                                                                            <div className="card-media">
                                                                                <a target="_blank" href={idx.token_URL}><img src={idx.image ? idx.image : dummy} alt="Cyfonii" /></a>
                                                                                
                                                                                
                                                                            </div>
                                                                            <div className="card-title">
                                                                                <Link to="#" className="h5">{idx.name}</Link>
                                                                            </div>
                                                                             <div className="meta-info">
                                                                                <div className="author">
                                                                                    <div className="avatar">
                                                                                        <img src={img} alt="Cyfonii" />
                                                                                    </div>
                                                                                    <div className="info">
                                                                                        <span>From collection</span>
                                                                                        <a target="_blank" href={idx.collectionURL} className="h6">{idx.collectionName} </a>
                                                                                    </div>
                                                                                </div>
                                                                             
                                                                            </div> 
                                                                            <div className="card-bottom style-explode">
                                                                                <div className="price">
                                                                                    <span className="icon-logo-01"></span>
                                                                                    <div className="price-details">
                                                                                        <span> Points required</span>
                                                                                        <h6>{idx.price} </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="button-place-bid">
                                                                                    <button className="action-btn" onClick={() => handleOpen(idx)}><span>Buy</span></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                                <Modal
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        aria-labelledby="modal-modal-title"
                                                                        aria-describedby="modal-modal-description"
                                                                    >
                                                                        <Box sx={style} className='itemModal'>
                                                                            <div style={{backgroundColor: '#282B46', padding: 20, borderRadius:10}}>
                                                                                <h5 style={{marginBottom: 10}}>Are you sure you want to buy this one?</h5>
                                                                            <div style={{textAlign: 'center', marginBottom:10}}> 
                                                                                <a href={clickedItem?.token_URL}><img src={clickedItem?.image} alt="extratribes" style={{width: 200, borderRadius: 10}}/></a>  
                                                                            </div>
                                                                        <h5>
                                                                            {clickedItem?.collectionName}&nbsp;{clickedItem?.name}
                                                                        </h5>
                                                                        <h6 style={{fontSize: 12, marginTop:5}}>
                                                                            {clickedItem?.price} Points
                                                                        </h6>
                                                                        <Box style={{marginTop: 20, textAlign: 'right'}}>
                                                                            <button className="action-btn" onClick={handleClose} style={{marginRight: 10}}><span style={{fontSize: 12}}>Cancel</span></button>
                                                                          
                                                                            <button className="action-btn" onClick={handleBuy} disabled={loading} ><span style={{width: 110, fontSize: 12, textAlign: 'center'}}>{loading ? <CircularProgress size={15}/> : <>{"Confirm"}</>}</span></button>
                                                                        </Box>
                                                                            </div>
                                                                           
                                                                        </Box>
                                                                      
                                                                    </Modal>
                                                            

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            ))
                                        }
                                        </TableBody>
                                                </Table>
                                               { data.code===0 && 
                                               <TablePagination
                                                    className='pagination-component'
                                                    rowsPerPageOptions={[10, 20, 30, 50]}
                                                    component="div"
                                                    count= {data.totalSize}
                                                    rowsPerPage={param.pageSize}
                                                    page={param.page}
                                                    onPageChange={handlePageChange}
                                                    onRowsPerPageChange={handlePageSizeChange}
                                                    />}
                                                </TableContainer> 

                                    
                                </Tabs> 
                            </div>
                        
                        </div>

                     

                    </div>
                </div>
            </section>
    );
}

export default Project3;