import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import authJwtReducer from './slices/authJwt';
import staffReducer from './slices/game';
import walletReducer from './slices/wallet';
import shopReducer from './slices/shop';
import lotteryReducer from './slices/lottery'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated'],
};

const rootReducer = combineReducers({
  game: staffReducer,
  wallet: walletReducer,
  user: userReducer,
  shop: shopReducer,
  lottery: lotteryReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
});

export { rootPersistConfig, rootReducer };
