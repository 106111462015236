import React, { useEffect ,useState} from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { Tab, TabList, Tabs } from 'react-tabs';
import Footer from '../components/footer/Footer';
import DepositEuro from '../components/temptitle/DepositEuro';
import { loadStripe } from "@stripe/stripe-js";
import DepositRekk from '../components/temptitle/DepositRekk';
import ClaimRekk from '../components/temptitle/ClaimRekk'

function TempTitle(props) {  
    const [clickedTab, setClickedTab] = useState(1)
    const [stripeObject, setStripeObject] = useState(null);

    useEffect(() => {
      const fetchStripeObject = async () => {
        const res = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        if (res) {
          setStripeObject(res);
        }
      };
      fetchStripeObject();
    }, []);

      const handleTabClick = (e) => {
        setClickedTab(e)
    }

    return (
        <div className='page-advisor wrapper'>

        <section className="page-title">
                <div className="shape"></div>
                <div className="shape right s3"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title__body">
                                <div className="page-title__main">
                                    <h4 className="title">Deposit</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials ">
              <div className='testimonials__main'>
                <div className="container">
                    <div className="row justify-content-center">

                    <div className="col-12">
                        <div className="faq__main flat-tabs">

                                <Tabs>
                                    <TabList className='menu-tab'>
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(1)}>Deposit Euros</Tab> 
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(2)}>Deposit KARE</Tab>    
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(3)}>Claim KARE</Tab>        
                                    </TabList>   
                                </Tabs> 
                            </div>
                     

                     

                    </div>


                      { clickedTab === 1 ?
                       <div className="col-xl-6 col-md-12">
                             <>
                              {stripeObject && (
                                <Elements stripe={stripeObject}>
                                  <DepositEuro />
                                </Elements>
                              )}
                            </>
                        </div>: 
                        clickedTab === 2 ?
                        <div className="col-xl-6 col-md-12">
                          <DepositRekk />
                         </div> : 
                          clickedTab === 3 ?
                          <div className="col-xl-6 col-md-12">
                            <ClaimRekk />
                           </div> : ""
                      }
                        
                            
                    </div>
                    <div className="block-text center">
                       
                    </div>
                </div>
                </div>
            </section>
            {/* <Footer /> */}
        </div>
    );
}

export default TempTitle;
