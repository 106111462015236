import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


Roadmap.propTypes = {
    data: PropTypes.array
};

function Roadmap(props) {
    const {data} = props;

    return (
        <section className="roadmap s2">
                    <div className="shape"></div>
                    <div className="container" style={{marginTop: 40}}>
                        <div className="row">
                            <div className="col-12">

                                <div className="roadmap__main s1">
                                <Swiper
               
                                    spaceBetween={30}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            },
                                        500: {
                                            slidesPerView: 2,
                                            },
                                        768: {
                                            slidesPerView: 4,
                                        },
                                        991: {
                                            slidesPerView: 6,
                                        },
                                    }}
                                    className="roadmap-swiper"
                                    loop= {true}

                                    modules={[Autoplay, Pagination, Navigation]}
                                    
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                >
                                {
                                    data.map((idx, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="roadmap-box">
                                                        <div className="time">{idx.name}</div>
                                                        <div className="content">
                                                            <h5 className="title">{idx.content1}</h5>
                                                            <img src={idx.image} alt="" style={{width: '100%', marginBottom: 10}}/>
                                                            <p className="text">{idx.content2}</p>
                                                        </div>
                                                    </div>
                                        </SwiperSlide>
                                        
                                    ))
                                }
                                 {
                                    data.map((idx, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="roadmap-box">
                                                        <div className="time">{idx.name}</div>
                                                        <div className="content">
                                                            <h5 className="title">{idx.content1}</h5>
                                                            <img src={idx.image} alt="" style={{width: '100%',marginBottom: 10}}/>
                                                            <p className="text">{idx.content2}</p>
                                                        </div>
                                                    </div>
                                        </SwiperSlide>
                                        
                                    ))
                                }
                            </Swiper>


                                    
                                </div>


                            </div>

                        </div>
                    </div>
                </section>
    );
}

export default Roadmap;