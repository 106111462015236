import React, { useEffect ,useState} from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import avt1 from '../assets/images/layouts/avt-02.png'
import {getProfile} from '../redux/slices/user'
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
import { Navigate, useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import './Profile.scss'

import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

import { styled } from '@mui/material/styles';

import axios from 'axios'
import configData from '../config.json';
import { ethers,providers } from "ethers"

import { useWeb3React } from "@web3-react/core";
import { getWalletList } from '../redux/slices/wallet';
import './Profile.scss'
import { connectors } from "./connectors";

import Web3 from "web3";

import closeFill from "@iconify-icons/eva/close-fill";


import { Icon, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

import { tableCellClasses } from '@mui/material/TableCell';
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import { Tab, TabList, Tabs } from 'react-tabs';
import { Table, TableContainer, TableHead, TableRow , TableCell, Paper, TableBody} from '@mui/material';
import { getAffiliations } from '../redux/slices/game';
import _ from "lodash"
import { _deleteSession } from '../redux/slices/authJwt';


function Profile(props) {
    const dispatch = useDispatch();
    const { user } = useAuth(); 
    const { profile } = useSelector((state) => state.user);
    const { affiliations } = useSelector((state) => state.game);
    const { walletList } = useSelector((state) => state.wallet);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [message, setMessage] = useState(null);
    const [clickedTab, setClickedTab] = useState(1)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { library,  account, activate, deactivate, error} = useWeb3React();
    const navigate = useNavigate();
    const [name, setName] = useState()
    const [ENS, setENS] = useState()
    const [loading, setLoading] = useState(false)
  
   var globalSignature



  // Set MM/walletConnect provider in localStorage
  const setProvider = (type) => { window.localStorage.setItem("provider", type) };

  // Unset MM/walletConnect provider in localStorage
  const refreshState = () => { window.localStorage.setItem("provider", undefined) };

  const disconnect = () => {
    refreshState();
    deactivate();
    setName()
  };

 

  const lookupENS = async () => {
   
    const provider = await library.provider;
    const web3Provider = new providers.Web3Provider(provider)
    console.log({ account })
    const _ens = await web3Provider.lookupAddress(account)
    console.log(_ens)
    if (_ens) setENS(_ens)
  }


  const connectMetaMask = async () => {
    let isCancelled = false;
    try {
        await activate(connectors.injected, () => {
          
          });
        // await activate(connectors.injected, () => {
        //     window.alert("MetaMask Connection Rejected");
        //     isCancelled = true;
        //   });
    } catch (err) {
        console.log(err)
        isCancelled = true;
    }


    if (!isCancelled) {
      setProvider("injected");
      console.log("MetaMask Connected Successfully");
      enqueueSnackbar("MetaMask Connected Successfully", {
        variant: "success",
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        ),
      });
      console.log({ account })
      await signMessage()
      disconnect()
      dispatch(getWalletList());
    }
  }

  const connectWalletConnect = async () => {
    let isCancelled = false;
    console.log(connectors.walletConnect)
    await activate(connectors.walletConnect, () => {
        console.log("Wallet Connect Connection Rejected");
        enqueueSnackbar( "Wallet Connect Connection Rejected", {
          variant: "error",
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        });
        
      isCancelled = true;
    });

    if (!isCancelled) {
      setProvider("walletConnect");
      console.log("Wallet Connect Connected Successfully");
      enqueueSnackbar("Wallet Connect Connected Successfully", {
        variant: "success",
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        ),
      });
      console.log({ account })
      try {
        await  signMessageW()
      } catch (error) {
        console.log(error)
      }
    
      disconnect()
      dispatch(getWalletList());
    }
  }

  useEffect(() => {
    if (error) {
    console.log(error)
    }
  }, [error]);
  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
  }, [activate]);

  useEffect(() => {
    console.log({ account })
    if (!account) return;
    if (!library) return;
    lookupENS().then(() => {
      console.log("DONE")
    }).catch(err => console.log('err', err))

  }, [account, library])
//---------



//--------



     useEffect(() => {
        document.body.style.overflow = 'unset';
        if(!_.isEmpty(user) && user?.displayName!==null){

          console.log("toto",user)

            dispatch(getProfile(user.id));
            dispatch(getWalletList());
            dispatch(getAffiliations())
        }   
      }, [user]); 

    //   if(!user){
    //     return <Navigate to="/" replace/>;
    // }
    async function signMessage() {
        console.log("CurrentAccount:",account)
      if(account===null){

        console.log("not connected")
        return
      }
      var retMessage=null
   await  axios.post(configData.SERVER_URL + configData.signingAuth)
            .then(function (response) {
               
                if (response.data.error === 0) {
                    setMessage(response.data.results);
                    retMessage=response.data.results
                }
                else{
                    console.log("error 58")

                }
        })

        
    if(retMessage!==null){

        try {

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            console.log(provider);

            const signer = provider.getSigner();
            const signature = await signer.signMessage(retMessage);
            const address = await signer.getAddress();
            globalSignature=signature
    
           
      await   axios.post(configData.SERVER_URL + configData.addWallet,{
        address:address ,

        message:retMessage,
        globalSignature:globalSignature,
        provider:"injected"
    })
            .then(function (response) {
               console.log(response)
               setMessage(null)
               if (response.data.error === 0) {
                enqueueSnackbar( response?.data?.message, {
                  variant: "success",
                  action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                      <Icon icon={closeFill} />
                    </IconButton>
                  ),
                });
                  setMessage(response.data.results);
                  retMessage=response.data.results
              }
              else{
                  console.log("error 58")
                  enqueueSnackbar( response?.data?.message, {
                    variant: "error",
                    action: (key) => (
                      <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                      </IconButton>
                    ),
                  });

              }
        })


        } catch (err) {
            console.error(err);
            if(error?.request?.status === 477){
              _deleteSession();
              navigate('/login', { replace: true });
             }
            setMessage(null)
        }
      





    }else{

        console.log("an error occured, try again later")


    }
       

}
async function signMessageW() {
    console.log("CurrentAccount:",account)
  if(account===null){

    console.log("not connected")
    return
  }
  var retMessage=null
await  axios.post(configData.SERVER_URL + configData.signingAuth)
        .then(function (response) {
           
            if (response.data.error === 0) {
                setMessage(response.data.results);
                retMessage=response.data.results
            }
            else{
                console.log("error 58")
            }
    })

    
if(retMessage!==null){

    try {
     //   const provider = new ethers.providers.Web3Provider(connectors.walletConnect);
    //    const provider = new ethers.providers.Web3Provider(`https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e`);
     //   const provider = new ethers.providers.InfuraProvider(null, "https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e")
       //var provider=new Web3(`https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e`)
     // var provider=new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/2100888ec1b94c45b2167c5fee056c1e'));
     const walletConnectProvider = new WalletConnectProvider({
        infuraId: "2100888ec1b94c45b2167c5fee056c1e" // Required
        // qrcode: true
      });
      var wc = walletConnectProvider;
      await walletConnectProvider.enable();
var web3 = new Web3(walletConnectProvider);

var rawMessage =retMessage;
var rawMessageLength = new Blob([rawMessage]).size;
var message = ethers.utils.toUtf8Bytes(
  "\x19Ethereum Signed Message:\n" + rawMessageLength + rawMessage
);

message = ethers.utils.keccak256(message);
var params = [
  // await this.signer.getAddress(),
  wc.accounts[0],
  message
];
var signature = await wc.connector.signMessage(params);

        globalSignature=signature

       
  await   axios.post(configData.SERVER_URL + configData.addWallet,{
    address:wc.accounts[0] ,
    message:rawMessage,
    globalSignature:globalSignature,
    provider:"walletconnect"

})
        .then(function (response) {
           console.log(response)
           setMessage(null)
           if (response.data.error === 0) {
            enqueueSnackbar( response?.data?.message, {
              variant: "success",
              action: (key) => (
                <IconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </IconButton>
              ),
            });
              setMessage(response.data.results);
              retMessage=response.data.results
          }
          else{
              console.log("error 58")
              enqueueSnackbar( response?.data?.message, {
                variant: "error",
                action: (key) => (
                  <IconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </IconButton>
                ),
              });

          }
    })


    } catch (err) {
        console.error(err);
        if(error?.request?.status === 477){
          _deleteSession();
          navigate('/login', { replace: true });
         }
        setMessage(null)
    }
  





}else{

    console.log("an error occured, try again later")


}
   

}

    const checkMetaMaskIsConnected = async () => {
        const {ethereum}=window;

        if(!ethereum){
            console.log("Metamask not installed!")
            return
        }
       var prov= localStorage.getItem("provider");
        if(prov=="Metamask"){
            console.log("reconnect to metamask")
            const accounts=await ethereum.request({method:'eth_requestAccounts'})
            if(accounts.length!==0){
                const account=accounts[0]
                setCurrentAccount(account)
            }else{
    
                console.log("No account found")
            }
    


        }



     }
 
    const connectMetamaskButton = () => {
        return (
          <button onClick={connectMetaMask} className='action-btn'>
            <span>Connect with Metamask</span>
          </button>
        )
      }
      const signMessageButton = () => {
        if(currentAccount!==null) {
            return (
                <button onClick={signMessage} className="action-btn">
                  <span>Sign Message</span>
                </button>
              )

        }
       
      }   
      const connectWalletConnectButton = () => {
        return (
            <button onClick={connectWalletConnect} className="action-btn"><span>Connect with WalletConnect</span></button>   
   
        )
      }

      // const disconnectButton = () => {
      //   if(account!==undefined){
      //       return (
      //           <button onClick={disconnect} className="action-btn"><span>Disconnect</span></button>
    
       
      //       )
      //   }
       
      // }
      const handleTabClick = (e) => {
        setClickedTab(e)
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#070A29",
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      
        backgroundColor: "#191E4F",
        
      // hide last border
      '&:last-child td, &:last-child th': {
        // border: 0,
      },
    }));

    const onPrimaryWallet = async (e) => {
      try{
        const response = await axios.post(configData.SERVER_URL + configData.primaryWallet, {
          walletAddress: e
        });
        if(response && response.data.error === 0){
          enqueueSnackbar( response?.data?.message, {
              variant: "success",
              action: (key) => (
                <IconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </IconButton>
              ),
            });
          
         }else if (response  && response.data.error !== 0) {
           enqueueSnackbar( response?.data?.message, {
             variant: "error",
             action: (key) => (
               <IconButton size="small" onClick={() => closeSnackbar(key)}>
                 <Icon icon={closeFill} />
               </IconButton>
             ),
           });
         } 
      }catch (err) {
        console.log(err)
        if(err?.request?.status === 477){
          _deleteSession();
          navigate('/login', { replace: true });
         }
      }
      dispatch(getWalletList());
    }


    return (
        <div className='page-advisor wrapper'>

        <section className="page-title">
                {/* <div className="shape"></div> */}
                {/* <div className="shape right s3"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title__body">
                                <div className="page-title__main">
                                    <h4 className="title">Profile</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials ">
              <div className='testimonials__main'>
                <div className="container">
                    <div className="row justify-content-center">

                    <div className="col-12">
                        <div className="faq__main flat-tabs">

                                <Tabs>
                                    <TabList className='menu-tab'>
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(1)}>Account Details</Tab> 
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(2)}>My Wallets</Tab>     
                                      <Tab className='fs-14 h6' onClick={() => handleTabClick(4)}>Affiliations</Tab>    
                                      <Tab className='fs-14 h6' disabled style={{opacity: 0.2}}>My Earnings</Tab> 
                                      <Tab className='fs-14 h6' disabled style={{opacity: 0.2}}>Security</Tab>       
                                    </TabList>   
                                </Tabs> 
                            </div>
                     

                     

                    </div>


                      { clickedTab === 1 ?
                       <div className="col-xl-6 col-md-12">
                            <div className="block-text center">
                            
                                <div className="box-testimonial center">
                                    <div className="profile-info">
                                        <img src={avt1} alt="Cyfonii" />
                                        <h5 className="name" style={{fontSize: 25}}>{profile?.result?.email}</h5>
                                        <h6 style={{marginBottom: 10}}>You currently have : {profile?.result?.points.toFixed(2)} points</h6>
                                        <h6 style={{marginBottom: 10}}>You played: {profile?.result?.nbGame} Games</h6>
                                        <h6 style={{marginBottom: 40}}>ExtraCard affiliation code: {profile?.result?.affCode}</h6>
                                        <h6 style={{marginBottom: 40}}>You are Level: {profile?.result?.level}</h6>
                                    </div>
                                </div>

                              

                            </div>   
                            <div className="block-text center" style={{marginTop: 50}}>
                        {/* <h6 className="sub-heading"><span>This is your profile. Be sure to check the leaderboard and get your prizes! Join our Discord to get updates! This website is currently under construction.</span></h6> */}
                    </div>
                        </div>
                        : 
                        clickedTab === 2 ?
                        <div className="col-xl-6 col-md-12" style={{maxWidth: 800}}>
                              <div className="block-text center" >
                              <div style={{paddingBottom:20}}>
                              { walletList.results && walletList.results.length>0 ? walletList?.results?.map((item, index) =>  (
                                  <div className='walletList'>
                                    <h6 style={{marginRight:10}} className={item.isPrimary === 1 ? "primary-address" : ""}>{item.walletAddress}</h6>
                                    
                                    <h6 style={{fontSize:12, marginTop:2}} className={item.isPrimary === 1 ? "primary-date" : ""}>{item.dateAdded}</h6>
                                  { item.isPrimary !== 1 && <Tooltip title="Made this address a primary one">
                                      <BookmarkAddedIcon className='icon' sx={{color: "#DEC7FF", cursor: 'pointer' }} onClick={() => onPrimaryWallet(item.walletAddress)}/>
                                    </Tooltip>}
                                  </div>          
                                  )
                                ) : 
                                <>
                                 <h5 style={{textAlign: 'center', marginBottom:20}}>Why do I need to connect my wallet?</h5>
                                  <h6 style={{textAlign: 'center'}}>
                                  We need you to sign a transaction, without sending anything, to prove that this is your wallet. This way, we will be able to check which NFTs are in your wallet, and grant you access to new games or priviledges associated to some of these NFTs, like access to different shops, or bonus % on the points that you get from specific games.
                                    
                                     </h6>       </>
                               
                                }
                              </div>
                              
                              <div style={{display:'flex', flexWrap:'wrap'}}>
                                <div style={{marginRight: 10, marginBottom:10}}>
                                  {connectMetamaskButton()}
                                </div>
      

                                <div style={{marginRight: 10, marginBottom:10}}>
                                  {connectWalletConnectButton()}
                                </div>
                         
{/*                              
                                 <div>
                                  {signMessageButton()}
                                </div> 

                                 <div style={{marginRight: 10, marginBottom:10}}>
                                
                                {disconnectButton()}
                               
                                </div>  */}
                                
                                </div>
                              </div>
                         </div> : clickedTab === 4 ? 
                         <div className='col-12' style={{maxWidth: 1000}}>
                          <TableContainer component={Paper} sx={{p: 0}}>
                          <Table sx={{overflowX:'auto'}}>
                            <TableHead>
                              <TableRow >
                                <StyledTableCell sx={{borderRight: "1px solid black"}}><h5 style={{fontSize: 16, fontWeight: 'bold'}}>Your code</h5></StyledTableCell>
                                <StyledTableCell><h5 style={{fontSize: 16, fontWeight: 'bold'}}>Your Affiliate</h5></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>                                                 
                                {affiliations?.result?.map((item, index) => (
                                  <>
                                   <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black", borderBottom: 'none'}}><div style={{display: 'flex'}}><h6 style={{width: 100}}>{item.type}</h6><h6 style={{color: 'gray'}}>: {item.code}</h6> </div></TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none'}}>First Generation</TableCell>
                                  </StyledTableRow>
                                  <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black", borderBottom: 'none'}}></TableCell>
                                        <TableCell>{item.firstDegreeHistory.map((x) => (<h6 style={{color: 'gray'}}>{x.affiliateEmail}</h6>))}</TableCell>
                                  </StyledTableRow>
                                  <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black", borderBottom: 'none'}}></TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none'}}>Second Generation</TableCell>
                                  </StyledTableRow>
                                  <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black", borderBottom: 'none'}}></TableCell>
                                        <TableCell>{item.secondDegreeHistory.map((x) => (<h6 style={{color: 'gray'}}>{x.affiliateEmail}</h6>))}</TableCell>
                                  </StyledTableRow>
                                  <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black", borderBottom: 'none'}}></TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none'}}>Third Generation</TableCell>
                                  </StyledTableRow>
                                  <StyledTableRow key={index}>
                                        <TableCell sx={{borderRight: "1px solid black",}}></TableCell>
                                        <TableCell >{item.thirdDegreeHistory.map((x) => (<h6 style={{color: 'gray'}}>{x.affiliateEmail}</h6>))}</TableCell>
                                  </StyledTableRow>
                                  </>
                                   
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer> 
                       </div>
                      :""
                      }
                        
                            
                    </div>
               
                </div>
                </div>
            </section>
            {/* <Footer /> */}
        </div>
    );
}

export default Profile;
